import { Modal, Box, Typography } from '@mui/material'
import { DataGrid, GridToolbar, type GridCellParams } from '@mui/x-data-grid'
import { api } from 'api/rest'
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Button } from 'components'
import { NoRowsOverlay } from 'components/DataGrid/NoRows'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

type InvoiceAnalysis = {
  analysis: Analysis[]
}

type InvoiceDayAnalysis = {
  analysis: DayAnalysis[]
}

type Analysis = {
  process_date: string
  total_staff: number
  total_processes: number
  avg_per_staff: number
}

type DayAnalysis = {
  staff_id: number
  staff_name: string
  processes: number
}

export function AnalysisScreen(): React.ReactElement {
  const [rows, setRows] = useState<Analysis[]>([])
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [modalData, setModalData] = useState<DayAnalysis[]>([])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.get<InvoiceAnalysis>('/invoices/analysis')

      setRows(
        result.data.analysis
          .map((row, index) => ({ ...row, id: index }))
          // ? Sort by process date
          .reverse() as Analysis[],
      )
    }

    fetchData()
  }, [])

const handleDateClick = async (date: string) => {
  const utcDate = dayjs.utc(date).format('YYYY-MM-DD')
  setSelectedDate(utcDate)
  const result = await api.get<InvoiceDayAnalysis>(
    `/invoices/analysis?date=${utcDate}`,
  )
  setModalData(
    result.data.analysis.map((row, index) => ({
      ...row,
      id: index,
    })) as DayAnalysis[],
  )
  setOpen(true)
}


  const renderCell = (params: GridCellParams) => {
    const date = dayjs.utc(params.value as string)
    const dayOfWeek = date.format('dddd')
    const isToday = date.isSame(dayjs.utc(), 'day')
    const formattedDate = `${dayOfWeek} ${date.format('YYYY-MM-DD')} ${
      isToday ? '(Today)' : ''
    }`
    return (
      <Button
        className="w-full"
        onClick={() => handleDateClick(params.value as string)}
        variant="contained"
        style={{ fontWeight: 'bold' }}>
        {formattedDate}
      </Button>
    )
  }

  const columns = [
    {
      field: 'process_date',
      headerName: 'Process Date (UTC - Click to Expand)',
      flex: 1,
      renderCell,
    },
    { field: 'total_staff', headerName: 'Total Staff', flex: 1 },
    { field: 'total_processes', headerName: 'Total Processes', flex: 1 },
    { field: 'avg_per_staff', headerName: 'Avg Per Staff', flex: 1 },
  ]

  const modalColumns = [
    { field: 'staff_id', headerName: 'Staff ID', width: 150 },
    { field: 'staff_name', headerName: 'Staff Name', width: 150 },
    { field: 'processes', headerName: 'Processes', width: 150 },
  ]

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        slots={{
          toolbar: () => (
            <div className="w-full flex justify-end">
              <GridToolbar />
            </div>
          ),
          noRowsOverlay: () => <NoRowsOverlay loading />,
        }}
        rows={rows}
        columns={columns}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            p: 4,
            backgroundColor: 'white',
            margin: 'auto',
            width: 600,
            borderRadius: 2,
          }}>
          <Typography variant="h6" component="h2">
            Details for {selectedDate}
          </Typography>
          <DataGrid rows={modalData} columns={modalColumns} />
        </Box>
      </Modal>
    </div>
  )
}
