import { type Product, ProductStatus, type Unit, type Ingredient } from 'api'
import { INITIAL_PRODUCT } from 'app/constants'
import type {
  TFormValues,
  TInitialProduct,
  TProductOption,
  TProductSearchResult,
  TSupplierOption,
} from 'app/types'
import type { FormikContextType } from 'formik'
import { isEmpty, isNil } from 'ramda'
import { catalogNotUsed, catalogUsed } from 'styles/colors'

import type { Item } from '../Buttons/MagicButton/TSuggestion'

export const isUnit = (value: string | Unit | undefined): value is Unit => {
  return value !== undefined
}

export const keyDownEventPrevention = (
  event:
    | React.KeyboardEvent<HTMLInputElement>
    | React.KeyboardEvent<HTMLDivElement>,
): void => {
  const code = event.keyCode
  if (code === 40 || code === 38) {
    event.preventDefault()
  }
}

export const createEmptyProductWithUnit = (
  unit: Unit,
  initalProduct?: TInitialProduct,
  useSuppliedUnit = false,
): TInitialProduct => {
  if (initalProduct) {
    return {
      ...initalProduct,
      initial: {
        ...initalProduct.initial,
        unit: initalProduct.unit,
      },
      unit: useSuppliedUnit ? unit : initalProduct.unit,
    }
  }

  return {
    ...INITIAL_PRODUCT,
    initial: {
      ...INITIAL_PRODUCT,
      unit,
    },
    unit,
  }
}

export const createPendingProductFromSuggestionItem = (
  product: Item,
  units: Unit[],
): TInitialProduct => {
  const { code, name, price, quantity, productId, unitId } = product

  const unit = units.find((u) => u.id === unitId)
  if (!unit) {
    throw new Error(`Unit ID not found: ${unitId.toString()}`)
  }

  const initial = {
    ...INITIAL_PRODUCT,
    code,
    name: name ? name.toUpperCase() : '',
    price,
    quantity,
    unit,
  }

  return {
    ...INITIAL_PRODUCT,
    code,
    name: name ? name.toUpperCase() : '',
    price,
    quantity,
    productId,
    unit,
    status: ProductStatus.Pending,
    initial,
  }
}

export const determineRank = (
  product: Product | TProductSearchResult,
): number => {
  switch (product.status) {
    case ProductStatus.Custom:
      return product.ingredients.length > 0 ? 1 : 3
    case ProductStatus.Pending:
      return 2
    case ProductStatus.Available:
      return product.ingredients.length > 0 ? 4 : 5
    default:
      return 6
  }
}

export const shouldSaveProductRow = (
  product: TInitialProduct | TProductOption,
): boolean => {
  return (
    (!isNil(product.quantity) && !isEmpty(product.quantity)) ||
    (!isNil(product.name) && !isEmpty(product.name)) ||
    (!isNil(product.packSize) && !isEmpty(product.packSize)) ||
    (!isNil(product.unitValue) && !isEmpty(product.unitValue)) ||
    (!isNil(product.price) && !isEmpty(product.price))
  )
}

export const updateProductsField = (
  formik: FormikContextType<TFormValues>,
  product: TInitialProduct,
  rowIndex: number,
): boolean => {
  const previousProduct = formik.values.products[rowIndex]
  
  // @ts-expect-error this is a hack to get around the fact that the price is a string
  let price = !previousProduct.price ? parseFloat(product.price) : parseFloat(previousProduct.price ?? 0)
  // @ts-expect-error this is a hack to get around the fact that the quantity is a string
  const quantityNumber = parseFloat(previousProduct.quantity?.toFixed(3) ?? 0)

 // @ts-expect-error this is a hack to get around the fact that the quantity is a string
  const total = parseFloat(price * quantityNumber)
  
  

  const productChanged = {
    ...product,
    quantity: quantityNumber === 0 ? undefined : quantityNumber,
    price: price.toFixed(2),
    total: total.toFixed(2),
    confidence: stringCompare(product.name.replace(/[^a-zA-Z0-9 ]/g, '') || '', previousProduct.name.replace(/[^a-zA-Z0-9 ]/g, '') || ''),
  }

  formik.setFieldValue(`products[${rowIndex}]`, productChanged)

  return true
}

export function percentageToColor(percentage: number): string {
  const hue = (percentage / 100) * 120 // Map percentage to hue range (0-120)
  const saturation = 100 // Set saturation to 100%
  const lightness = 50 // Set lightness to 50%

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

export const getLatestIngredient = (ingredients: Ingredient[]): Ingredient => {
  return ingredients.reduce((acc, ingredient) => {
    return acc.updatedAt > ingredient.updatedAt ? acc : ingredient
  })
}

export const productHasIngredients = (
  product: Product | TProductOption,
): boolean => {
  return !!product.ingredients && product.ingredients.length > 0
}

export const createExistingInitialProduct = (i: Product): TInitialProduct => {
  const {
    code,
    name: lowerName,
    packSize,
    status,
    ingredients,
    unit,
    unitValue: productUnitValue,
    id: productId,
    updatedBy: productUpdatedBy,
    updatedAt: productUpdatedAt,
  } = i

  // ? if suggestPrice is true then the price has come from OCR and we can use it
  // ? Otherwise we should use the price from the ingredients (prices they have paid before)
  let ingredient
  let conversionUnit
  let conversionUnitType
  let conversionUnitValue

  if (productHasIngredients(i)) {
    ingredient = getLatestIngredient(ingredients)

    const {
      conversionUnitType: ingCUT,
      conversionUnit: ingCU,
      conversionUnitValue: ingCUV,
    } = ingredient
    conversionUnit = ingCU as number | undefined
    conversionUnitType = ingCUT as Unit
    conversionUnitValue = ingCUV as number | undefined
  }

  const price = ingredient?.price ? ingredient.price : undefined

  const updatedBy = ingredient?.updatedBy ?? productUpdatedBy
  const updatedAt = ingredient?.updatedAt ?? productUpdatedAt

  const name = lowerName.toUpperCase()

  const initialProduct = {
    ...INITIAL_PRODUCT,
    code: code ?? '',
    conversionUnitType,
    conversionUnit,
    conversionUnitValue,
    confidence: 100,
    name,
    packSize,
    productId,
    price,
    status,
    unit,
    updatedBy,
    updatedAt,
    unitValue: productUnitValue,
  }

  return {
    ...initialProduct,
    initial: {
      ...initialProduct,
      ingredients,
    },
  }
}

export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const deepEqual = (obj1: any, obj2: any, debug = false): boolean => {
  if (obj1 === obj2) {
    return true
  }

  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false
    }
    if (!deepEqual(obj1[key], obj2[key], debug)) {
      return false
    }
  }

  return true
}

export const getProductOptionBackgroundColor = (
  product: TProductOption,
): string => {
  switch (product.status) {
    case ProductStatus.Pending:
      return '#f5d94e'
    case ProductStatus.Available:
      if (product.isUsed) {
        return catalogUsed
      }
      return catalogNotUsed
    default:
      return '#fff'
  }
}
const toHex = (color: number) => color.toString(16).padStart(2, '0')

export function darken(hex: string, amount = 0.2): string {
  // Validate hex format and parse the components
  let hexCode = hex.replace('#', '')

  if (hexCode.length === 3) {
    hexCode = [...hexCode].map((char) => char + char).join('')
  }

  if (hexCode.length !== 6) {
    throw new Error('Invalid hex color format.')
  }

  // Convert hex to RGB
  const r = Number.parseInt(hexCode.slice(0, 2), 16)
  const g = Number.parseInt(hexCode.slice(2, 4), 16)
  const b = Number.parseInt(hexCode.slice(4, 6), 16)

  // Darken each color component
  const darken = (color: number) =>
    Math.max(0, Math.min(255, Math.floor(color * (1 - amount))))

  const rDark = darken(r)
  const gDark = darken(g)
  const bDark = darken(b)

  // Convert back to hex
  return `#${toHex(rDark)}${toHex(gDark)}${toHex(bDark)}`
}

export const getProductBackgroundColor = (product: TInitialProduct): string => {
  const isUsed =
    product.initial.ingredients && product.initial.ingredients.length > 0
  switch (product.status) {
    case ProductStatus.Pending:
    case ProductStatus.Custom:
      return '#f5d94e'
    case ProductStatus.Available:
      if (isUsed) {
        return catalogUsed
      }
      return catalogNotUsed
    default:
      return '#fff'
  }
}

export const getSupplierOptionBackgroundColor = (
  supplierOption: TSupplierOption,
): string | undefined => {
  const { isKitchenMatch, real, label } = supplierOption

  if (label.length === 0) {
    return '#fff !important'
  }

  if (isKitchenMatch) {
    return catalogUsed
  }

  if (real) {
    return catalogNotUsed
  }

  if (label.length > 0) {
    return '#f5d94e !important'
  }

  return '#fff !important'
}

export function isProduct(value: unknown): value is Product {
  return typeof value === 'object' && value !== null && 'unit' in value
}

function levenshteinDistance(s1: string, s2: string): number {
  const len1: number = s1.length
  const len2: number = s2.length
  const dp: number[][] = Array.from({ length: len1 + 1 }, () =>
    Array.from({ length: len2 + 1 }),
  )

  for (let i = 0; i <= len1; i++) {
    dp[i][0] = i
  }

  for (let j = 0; j <= len2; j++) {
    dp[0][j] = j
  }

  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const cost: number = s1[i - 1] === s2[j - 1] ? 0 : 1
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1,
        dp[i][j - 1] + 1,
        dp[i - 1][j - 1] + cost,
      )
    }
  }

  return dp[len1][len2]
}

export function stringCompare(s1: string, s2: string): number {
  const distance: number = levenshteinDistance(s1, s2)
  const maxLength: number = Math.max(s1.length, s2.length)
  const similarity: number = maxLength - distance
  return (similarity / maxLength) * 100
}
