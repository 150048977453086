export const getCurrentUserToken = (): string | null =>
  localStorage.getItem('authorization')

export const setCurrentUserToken = (token: string): void => {
  localStorage.setItem('authorization', token)
}

export const clearCurrentUserToken = (): void => {
  localStorage.removeItem('authorization')
  indexedDB.deleteDatabase('graphcache')
}

export const getCurrentUserId = (): number | null => {
  const token = localStorage.getItem('authorization')
  if (token) {
    // eslint-disable-next-line prefer-destructuring
    const payload = token.split('.')[1]

    const decoded = JSON.parse(atob(payload))

    return Number.parseInt(decoded.userId)
  }
  return null
}

export const getCurrentEmail = () => localStorage.getItem('jeladmin:email')

export const setCurrentEmail = (email: string) =>
  localStorage.setItem('jeladmin:email', email)

export const clearCurrentEmail = () => {
  localStorage.removeItem('jeladmin:email')
}
