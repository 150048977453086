import { useQuery } from '@apollo/client'
import type { Query, QueryAdminKitchensArgs, Kitchen } from 'api'
import { SortOrder, QueryMode, Status } from 'api'
import { Autocomplete, TextField, CircularProgress } from 'components'
import debounce from 'lodash.debounce'
import { isNil, sortBy, compose, toLower, prop } from 'ramda'
import { useState, useEffect } from 'react'

import { adminKitchensQuery } from './graphql'

export function KitchenSelect({
  kitchenId,
  onChange,
}: {
  kitchenId?: number | null
  onChange: (kitchen?: Kitchen) => void
}): React.ReactElement {
  const [search, setSearch] = useState('')
  const [stateKitchenData, setStateKitchenData] = useState<
    { label: string; value: number }[]
  >([])
  const [localId, setLocalId] = useState<number | undefined>()

  useEffect(() => {
    if (!kitchenId) {
      return
    }

    setLocalId(kitchenId)
  }, [])

  useEffect(() => {
    if (localId) {
      // @ts-expect-error No params required
      setLocalId()
    }
  }, [search])

  const { data: kitchensData, loading: kitchensFetching } = useQuery<
    {
      adminKitchens: Query['adminKitchens']
    },
    QueryAdminKitchensArgs
  >(adminKitchensQuery, {
    variables: {
      cursor: localId
        ? {
            id: Number(localId),
          }
        : undefined,
      orderBy: [{ name: SortOrder.Asc }],
      take: 20,
      where: {
        NOT: [
          {
            status: {
              equals: Status.Inactive,
            },
          },
        ],
        OR: [
          {
            id: {
              equals: localId ? Number(localId) : undefined,
            },
          },
          {
            name: {
              contains: search,
              mode: QueryMode.Insensitive,
            },
          },
        ],
      },
    },
  })

  useEffect(() => {
    if (isNil(kitchensData)) {
      return
    }
    setStateKitchenData(
      sortBy<{ label: string; value: number }>(compose(toLower, prop('label')))(
        kitchensData.adminKitchens.map((kitchen) => ({
          label: kitchen.name,
          value: kitchen.id,
        })),
      ),
    )
    if (kitchenId) {
      const selectedKitchen = kitchensData.adminKitchens.find(
        (kit) => kit.id === Number(kitchenId),
      )
      if (selectedKitchen) {
        onChange(selectedKitchen)
      }
    }
  }, [kitchensData])

  const handleSetSearch = debounce((value: string) => {
    setSearch(value)
  }, 500)

  return (
    <Autocomplete
      autoHighlight
      filterSelectedOptions
      getOptionLabel={(option) => option.label}
      key={kitchenId && stateKitchenData.length === 0 ? 1 : 2}
      onChange={(_event, newValue) => {
        if (newValue) {
          setLocalId(newValue.value)
          onChange(
            kitchensData?.adminKitchens.find((k) => k.id === newValue.value),
          )
        }
      }}
      options={stateKitchenData}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {kitchensFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label="Select a Kitchen"
          onChange={(event) => {
            handleSetSearch(event.target.value)
          }}
          required
          variant="outlined"
        />
      )}
      size="small"
      sx={{ minWidth: '200px' }}
      value={stateKitchenData.find((k) => k.value === localId)}
    />
  )
}
