import type { PaletteMode, ThemeOptions } from '@mui/material'

import { PRIMARY, PRIMARY_DARK, SECONDARY, SECONDARY_DARK } from './colors'

export const themeOptions = (mode: PaletteMode): ThemeOptions => {
  return {
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: false,
        },
      },
    },
    palette: {
      mode,
      ...(mode === 'light'
        ? {
            primary: {
              main: PRIMARY,
            },
            secondary: {
              main: SECONDARY,
            },
          }
        : {
            primary: {
              main: PRIMARY_DARK,
            },
            secondary: {
              main: SECONDARY_DARK,
            },
          }),
    },
    typography: {
      fontFamily: 'Lato, sans-serif',
      fontSize: 12,
    },
  }
}
