import { Box, Link } from '@mui/material'
import { Typography } from 'components'
import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { lightBlue } from 'styles/colors'
import { v4 as uuidv4 } from 'uuid'

export function Breadcrumb({
  crumbs,
}: {
  crumbs: { label: string; route?: string }[]
}): React.ReactElement {
  return (
    <Box sx={{ display: 'flex' }}>
      {crumbs.map(({ label, route }, index) => (
        <Fragment key={`fragment-${uuidv4()}`}>
          {route ? (
            <Link
              component={NavLink}
              key={`label-${label}`}
              sx={{ color: lightBlue, textDecoration: 'none' }}
              to={route}
            >
              <Typography variant="h6">{label}</Typography>
            </Link>
          ) : (
            <Typography key={`label-${uuidv4()}`} variant="h6">
              {label}
            </Typography>
          )}
          {index < crumbs.length - 1 && (
            <Typography
              key={`breadcrumb-${label}`}
              sx={{ padding: '0 10px' }}
              variant="h6"
            >
              {'>'}
            </Typography>
          )}
        </Fragment>
      ))}
    </Box>
  )
}
