import { Box } from '@mui/material';
import { Kitchen } from 'api';
import { Button, Paper, Modal } from 'components';
import { toast } from 'react-toastify'


export default function DeleteInvoicesModal({
    deleteModal,
    setDeleteModal,
    selected,
    setSelected,
    selectedKitchen,
    deleteManyPendingInvoice,
    }: {
    deleteModal: boolean
    setDeleteModal: (deleteModal: boolean) => void
    selected: number[]
    setSelected: (selected: number[]) => void
    selectedKitchen: Kitchen | undefined
    deleteManyPendingInvoice: any
}) {
    return (
              <Modal
        onClick={() => {
          setDeleteModal(false)
        }}
        open={deleteModal}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '40px',
          }}
        >
          <Box>
            Delete {selected.length} invoice(s) from {selectedKitchen?.name}
          </Box>
          <Button
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            CLOSE
          </Button>
          <Button
            onClick={async () => {
              const res = await deleteManyPendingInvoice({
                variables: {
                  ids: selected,
                },
              })

              if (res.data?.deleteManyPendingInvoice) {
                toast.success(
                  `Deleted ${res.data.deleteManyPendingInvoice} pending invoice(s)`,
                )
              }
              setSelected([])
              setDeleteModal(false)
            }}
          >
            DELETE INVOICES
          </Button>
        </Paper>
      </Modal>
    )
}