import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box } from '@mui/material'
import {
  DataGrid,
  GridToolbar,
  type GridRenderCellParams,
} from '@mui/x-data-grid'
import { InputAdornment, SearchIcon, TextField, Typography } from 'components';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes'
import { lightBlue, lightGrey } from 'styles/colors';
import { NoRowsOverlay } from 'components/DataGrid/NoRows'
import DailyOverview from './DailyOverview/DailyOverview'
import { TKitchenInvoiceOverview, TPendingInvoiceOverview } from 'app/types'
import getInvoiceOverview from './getInvoiceOverview'
import { toast } from 'react-toastify'
import { useInvoice } from 'context/InvoiceContext'

export function KitchenInvoiceList(): React.ReactElement {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const [invoiceOverview, setInvoiceOverview] =
    useState<TPendingInvoiceOverview | null>(null)
  const [errored, setErrored] = useState(false)
  const { invoiceContext } = useInvoice()
  const { showAllKitchens } = invoiceContext
  const [rows, setRows] = useState<TKitchenInvoiceOverview[]>([])

  const getOverviewData = async (refresh: boolean) => {
    setLoading(true)
    try {
      const data = await getInvoiceOverview(refresh)
      if (!data && !errored) {
        toast.error('Error getting invoice overview')
        setErrored(true)
      }
      setInvoiceOverview(data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!invoiceOverview) {
      getOverviewData(false)
    }
  }, [invoiceOverview])

  useEffect(() => {
    if (!invoiceOverview) return

    setRows(
      showAllKitchens
        ? invoiceOverview?.premiumKitchens.concat(
            invoiceOverview?.unpaidKitchens,
          ).filter((kitchen) => {
            return kitchen.kitchenName
              .toLowerCase()
              .includes(searchTerm?.toLowerCase() ?? '') || kitchen.kitchenId.toString().toLowerCase().includes(searchTerm?.toLowerCase() ?? '')
          }).sort((a, b) => {
            if (a.invoicesRemaining < b.invoicesRemaining) {
              return 1
            }
            if (a.invoicesRemaining > b.invoicesRemaining) {
              return -1
            }
            return 0
          })
        : invoiceOverview?.premiumKitchens.filter((kitchen) => {
            return kitchen.kitchenName
              .toLowerCase()
              .includes(searchTerm?.toLowerCase() ?? '') || kitchen.kitchenId.toString().toLowerCase().includes(searchTerm?.toLowerCase() ?? '')
          }).sort((a, b) => {
            if (a.invoicesRemaining < b.invoicesRemaining) {
              return 1
            }
            if (a.invoicesRemaining > b.invoicesRemaining) {
              return -1
            }
            return 0
          }),
    )
    setLoading(false)
  }, [invoiceOverview, showAllKitchens, searchTerm])

  const columns = [
    {
      field: 'kitchenName',
      headerName: 'Kitchen Name',
      flex: 3,
    },
    { field: 'kitchenId', headerName: 'ID', flex: 1 },
    { field: 'tier', headerName: 'Tier', flex: 1 },
    {
      field: 'invoicesRemaining',
      headerName: 'Invoices Remaining',
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <div className="mt-1">
            {params.row.invoicesRemaining}

            <ChevronRightIcon
              aria-details={params.row}
              sx={{ color: lightBlue }}
            />
          </div>
        )
      },
    },
  ]

  const invoiceCount = showAllKitchens
    ? invoiceOverview?.unpaidKitchens
        .concat(invoiceOverview.premiumKitchens)
        .reduce((acc, kitchen) => acc + kitchen.invoicesRemaining, 0)
    : invoiceOverview?.premiumKitchens.reduce(
        (acc, kitchen) => acc + kitchen.invoicesRemaining,
        0,
      )

  const justifyContent = 'space-between'

  return (
    <div className="w-full overflow-hidden">
      <Box
        sx={{
          display: 'flex',
          justifyContent,
          marginBottom: '20px',
        }}>
        <DailyOverview refreshHandler={() => {
          setRows([])
          getOverviewData(true)}} />
        <div className="mt-4">
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => {
              setSearchTerm(event.target.value)
            }}
            placeholder="Filter by kitchen (ID or Name)"
            required={false}
            size="small"
            sx={{ width: '300px' }}
            value={searchTerm}
            variant="outlined"
          />
        </div>
      </Box>

      <Box sx={{ paddingBottom: '20px' }}>
        <div className="h-screen">
          <DataGrid
            density="compact"
            getRowId={(row) => row.kitchenId}
            rows={rows}
            columns={columns}
            slots={{
              toolbar: () => (
                <div className="w-full flex justify-between">
                      {invoiceOverview && (

<div className="flex p-4 justify-center space-x-4">
            <Typography variant="h5">
              <Box
                component="span"
                sx={{ color: lightBlue, fontWeight: 'bold' }}>
                {showAllKitchens
                  ? invoiceOverview?.unpaidKitchens.concat(
                      invoiceOverview.premiumKitchens,
                    ).length
                  : invoiceOverview?.premiumKitchens.length}
              </Box>{' '}
              Kitchens
            </Typography>
            <Typography variant="h5">
              <Box
                component="span"
                sx={{ color: lightBlue, fontWeight: 'bold' }}>
                {invoiceCount}
              </Box>{' '}
              Invoices
            </Typography>
</div>
      )}
                  <GridToolbar />
                </div>
              ),
              noRowsOverlay: () => <NoRowsOverlay loading={loading} />,
            }}
            sx={{
              cursor: 'pointer',
              columnHeaderRow: {
                backgroundColor: lightGrey,
                borderRadius: '5px',
              },
              '& .MuiDataGridPro-columnHeader': {
                backgroundColor: lightGrey,
              },
            }}
            onRowClick={(row) => {
              navigate(
                routes.UploadWithKitchen.replace(
                  ':id',
                  (row as { id: string }).id,
                ),
              )
            }}
          />
        </div>
      </Box>
    </div>
  )
}
