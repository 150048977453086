export const publicRoutes = {
  SignIn: '/signin',
}

export const privateRoutes = {
  Create: '/create',
  Edit: '/edit/:id',
  Home: '/',
  List: '/list',
  NextInQueue: '/next/:id', // kitchen id
  Process: '/',
  Upload: '/upload',
  UploadWithKitchen: '/upload/:id',
  View: '/view/:id',
  Analysis : '/analysis',
}

export const routes = {
  ...publicRoutes,
  ...privateRoutes,
}
