import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { captureException } from '@sentry/react'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'cross-fetch'

import { toast } from 'react-toastify'

import { API } from 'app'
import { clearCurrentUserToken, setCurrentUserToken } from 'utils'
import { getRefreshToken, tokenManager } from './TokenManager'

const retryLink = new RetryLink()

const logError = (error: string) => {
  try {
    captureException(new Error(error))
  } catch (error_) {
    // eslint-disable-next-line no-console
    console.log('errorLink: error calling Sentry.captureException', error_)
  }
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      logError(JSON.stringify(error))
      toast.error(error.message)
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(
          error.locations,
        )}, Path: ${JSON.stringify(error.path)}`,
      )
    })
  }
  return forward(operation)
})

const authorizationLink = setContext(async (_req, { headers }) => {
  try {
    const token = await tokenManager.getValidToken()
    return {
      headers: {
        ...headers,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    }
  } catch (error) {
    // Optionally handle token refresh failure
    return { headers }
  }
})

const refreshLink = new TokenRefreshLink({
  accessTokenField: 'refresh',
  fetchAccessToken: async () => {
    return await getRefreshToken()
  },
  handleFetch: (refresh: { token: string }) => {
    setCurrentUserToken(refresh.token)
  },
  handleError: (error) => {
    console.error('Cannot refresh access token:', error)
    clearCurrentUserToken()
  },
  isTokenValidOrUndefined: () => {
    return tokenManager.isTokenValid()
  },
})

const httpLink = createUploadLink({
  credentials: 'include',
  fetch,
  uri: API,
})

const cache = new InMemoryCache()

const link = from([
  refreshLink,
  authorizationLink,
  errorLink,
  retryLink,
  // @ts-expect-error
  httpLink,
])

export const client = new ApolloClient({
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
  link,
})
