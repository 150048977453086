import { ApolloProvider } from '@apollo/client'
import {
  CssBaseline,
  ThemeProvider,
  GlobalStyles,
  type PaletteMode,
  createTheme,
  useMediaQuery,
} from '@mui/material'
import { withProfiler } from '@sentry/react'
import { client } from 'api'
import { DateAdapter, LocalizationProvider } from 'components'
import { createContext, useMemo, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { Routes } from 'routes'
import { themeOptions } from 'styles'
import { highlightProduct } from 'styles/colors'

import 'react-toastify/dist/ReactToastify.css'
import { InvoiceProvider } from 'context/InvoiceContext'

type TColorModeContextType = {
  toggleColorMode: () => void
}

export const ColorModeContext = createContext<TColorModeContextType>({
  toggleColorMode: () => {
    throw new Error('toggleColorMode not implemented')
  },
})

function InnerContainer() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<PaletteMode>(() => {
    const savedMode = localStorage.getItem('color-mode')
    if (savedMode) {
      return savedMode === 'dark' ? 'dark' : 'light'
    }
    return prefersDarkMode ? 'dark' : 'light'
  })

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) => {
          const newMode = prevMode === 'light' ? 'dark' : 'light'
          localStorage.setItem('color-mode', newMode)
          return newMode
        })
      },
    }),
    [],
  )

  const theme = useMemo(() => createTheme(themeOptions(mode)), [mode])

  return (
    <ApolloProvider client={client}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={DateAdapter}>
          <InvoiceProvider>
            <CssBaseline />
            <GlobalStyles
              styles={{
                '.MuiAutocomplete-option[data-focus="true"]': {
                  background: highlightProduct,
                },
              }}
            />
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
            <ToastContainer />
          </InvoiceProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </ApolloProvider>
  )
}

export const Container = withProfiler(InnerContainer)
