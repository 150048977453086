import { useQuery } from '@apollo/client'
import type {
  InvoiceStatus,
  Query,
  QueryInvoicesArgs,
  QueryPendingInvoicesArgs,
} from 'api'
import { QueryMode, SortOrder } from 'api'
import dayjs from 'dayjs'
import { getInvoicesQuery, getPendingInvoicesQuery } from './graphql'

export const useHistoryList = ({
  completePagination,
  incompletePagination,
  selectedTab,
  debouncedSearch,
}: {
  incompletePagination: {
    skip: number
    take: number
  }
  completePagination: {
    skip: number
    take: number
  }
  selectedTab: number
  debouncedSearch: string | undefined
}) => {
  const {
    data: invoiceData,
    error: invoiceError,
    loading: invoiceLoading,
  } = useQuery<
    {
      invoices: Query['invoices']
      invoicesCount: Query['invoicesCount']
    },
    QueryInvoicesArgs
  >(getInvoicesQuery, {
    skip: selectedTab !== 0,
    variables: {
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      ...completePagination,
      where: {
        OR: debouncedSearch
          ? [
              {
                kitchen: {
                  name: {
                    contains: debouncedSearch,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
              {
                supplier: {
                  name: {
                    contains: debouncedSearch,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            ]
          : undefined,
        createdAt: debouncedSearch
          ? undefined
          : { gte: dayjs(Date.now()).subtract(5, 'days').toDate },
      },
    },
  })

  const {
    data: pendingData,
    error: pendingError,
    loading: pendingLoading,
  } = useQuery<
    {
      pendingInvoices: Query['pendingInvoices']
      pendingInvoicesCount: Query['pendingInvoicesCount']
    },
    QueryPendingInvoicesArgs
  >(getPendingInvoicesQuery, {
    skip: selectedTab !== 1,
    variables: {
      orderBy: [
        {
          createdAt: SortOrder.Desc,
        },
      ],
      ...incompletePagination,
      where: {
        status: {
          equals: 'rejected' as InvoiceStatus,
        },
        OR: debouncedSearch
          ? [
              {
                kitchen: {
                  name: {
                    contains: debouncedSearch,
                    mode: QueryMode.Insensitive,
                  },
                },
              },
              {
                createdBy: {
                  OR: [
                    {
                      firstName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                    {
                      lastName: {
                        contains: debouncedSearch,
                        mode: QueryMode.Insensitive,
                      },
                    },
                  ],
                },
              },
            ]
          : undefined,
        createdAt: debouncedSearch
          ? undefined
          : { gte: dayjs(Date.now()).subtract(5, 'days').toDate },
      },
    },
  })

  return {
    invoiceData: invoiceData?.invoices,
    pendingData: pendingData?.pendingInvoices,
    invoiceCount: invoiceData?.invoicesCount,
    pendingCount: pendingData?.pendingInvoicesCount,
    invoiceError,
    pendingError,
    invoiceLoading,
    pendingLoading,
  }
}
