import { Box } from '@mui/material';
import { Button } from 'components';


export default function KitchenInvoiceButtons({
    selected,
    merge,
    setModal,
    setDeleteModal,
    theme,
    }: {
    selected: number[]
    merge: any
    setModal: (value: boolean) => void
    setDeleteModal: (value: boolean) => void
    theme: any
}) {
    return (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Button
                color="primary"
                disabled={selected.length <= 1}
                onClick={() => {
                  merge({
                    variables: {
                      ids: selected,
                    },
                  })
                }}
                type="button"
                variant="contained"
              >
                Merge Invoices
              </Button>
              <Button
                color="primary"
                disabled={selected.length <= 0}
                onClick={() => {
                  setModal(true)
                }}
                sx={{
                  marginLeft: '10px',
                }}
                type="button"
                variant="contained"
              >
                Move Invoices
              </Button>
              <Button
                disabled={selected.length <= 0}
                onClick={() => {
                  setDeleteModal(true)
                }}
                sx={{
                  backgroundColor:
                    selected.length <= 0 ? undefined : theme.palette.error.main,
                  color:
                    selected.length <= 0
                      ? undefined
                      : theme.palette.common.white,
                  marginLeft: '10px',
                }}
                type="button"
                variant="contained"
              >
                Delete Invoices
              </Button>
            </Box>
    )
}