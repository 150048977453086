import { Box } from '@mui/material'
import { lightGrey } from 'styles/colors'

import { Breadcrumb } from '..'

export type Props = {
  crumbs: { label: string; route?: string }[]
}

export function VerticalDivider(): React.ReactElement {
  return (
    <Box
      sx={{
        backgroundColor: lightGrey,
        margin: ' 0 10px',
        padding: '20px 1px',
        position: 'relative',
        width: '1px',
        height: '24px'
      }}
    />
  )
}

export const BreadCrumbHeader: React.FunctionComponent<Props> = ({
  crumbs,
  children,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        paddingRight: '24px',
        paddingTop: '24px',
      }}
    >
      <Breadcrumb crumbs={crumbs} />
      <VerticalDivider />

      <Box sx={{ display: 'flex', flex: 1, minWidth: '200px' }}>{children}</Box>
    </Box>
  )
}
