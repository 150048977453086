import { Box } from '@mui/material'
import type { PendingInvoice } from 'api'
import { InvoiceImage, Modal, ImageViewer, Paper, Button } from 'components'
import { useState } from 'react'
import { resizeUrl } from 'utils/image'

export default function PendingInvoiceImages({ pi }: { pi: PendingInvoice }) {
  const [previewImages, setPreviewImages] = useState<InvoiceImage[]>()

  return (
    <div className="flex">
      <Modal
        onClick={() => {
          // @ts-expect-error No need to pass undefined
          setPreviewImages()
        }}
        open={!!previewImages}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '20px',
            paddingLeft: '4px', // This is to counter the built in padding on ImageViewer, i dont want to mess with the component
          }}>
          <ImageViewer images={previewImages ?? []} />
          <Button
            onClick={() => {
              // @ts-expect-error No need to pass undefined
              setPreviewImages()
            }}>
            CLOSE
          </Button>
        </Paper>
      </Modal>
      <Box
        onClick={() => {
          setPreviewImages(
            pi.signedImages.map((image, index) => {
              return {
                url: image,
                type: image.toLowerCase().includes('.pdf') ? 'pdf' : 'image',
                processedUrl:
                  !image.toLowerCase().includes('.pdf') &&
                  pi.imagesProcessedSigned[index]
                    ? pi.imagesProcessedSigned[index]
                    : null,
              }
            }),
          )
        }}
        onKeyDown={() => ''}
        role="button"
        sx={{
          cursor: 'pointer',
          display: 'flex',
          maxWidth: '300px',
          overflow: 'auto',
        }}
        tabIndex={0}>
        {pi.signedImages.map((image: string, index) => {
          let showImage = image
          if (!image.includes('.pdf') && pi.imagesProcessedSigned[index]) {
            showImage = resizeUrl(pi.imagesProcessedSigned[index], 200, 200)
          }
          return (
            <Paper
              elevation={5}
              key={showImage}
              sx={{
                height: '50px',
                margin: '10px',
                maxWidth: '50px',
                minWidth: '50px',
              }}>
              {image.toLowerCase().includes('.pdf') ? (
                <embed
                  src={showImage}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    pointerEvents: 'none',
                    width: '100%',
                  }}
                  type="application/pdf"
                />
              ) : (
                <img
                  alt=""
                  src={showImage}
                  style={{
                    height: '100%',
                    objectFit: 'cover',
                    width: '100%',
                  }}
                />
              )}
            </Paper>
          )
        })}
      </Box>
    </div>
  )
}
