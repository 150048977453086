import {
  Box,
  Tabs,
  Tab,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useState } from 'react'
import { useDebounce } from 'hooks'
import SearchIcon from '@mui/icons-material/Search'
import { completeColumns, incompleteColumns } from './columns/columns'
import { useHistoryList } from './useHistoryList'
import { NoRowsOverlay } from 'components/DataGrid/NoRows'

export function ListInvoice(): React.ReactElement {
  const [searchTerm, setSearchTerm] = useState<string | undefined>()
  const debouncedSearch = useDebounce(searchTerm, 500)
  const [selectedTab, setSelectedTab] = useState(0)
  const [completePaginationModel, setCompletePaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })
  const [incompletePaginationModel, setIncompletePaginationModel] = useState({
    page: 0,
    pageSize: 25,
  })

  const {
    invoiceData,
    pendingData,
    invoiceCount,
    pendingCount,
    invoiceError,
    pendingError,
    invoiceLoading,
    pendingLoading,
  } = useHistoryList({
    completePagination: {
      skip: completePaginationModel.page * completePaginationModel.pageSize,
      take: completePaginationModel.pageSize,
    },
    incompletePagination: {
      skip: incompletePaginationModel.page * incompletePaginationModel.pageSize,
      take: incompletePaginationModel.pageSize,
    },
    selectedTab,
    debouncedSearch,
  })

  const rows =
    selectedTab === 0
      ? invoiceData
        ? invoiceData.map((invoice) => ({
            ...invoice,
            id: invoice.id,
            pi: invoice.pendingInvoice,
          }))
        : []
      : pendingData
      ? pendingData.map((invoice) => ({
          ...invoice,
          id: invoice.id,
          pi: invoice,
        }))
      : []

  const error = selectedTab === 0 ? invoiceError : pendingError
  const loading = selectedTab === 0 ? invoiceLoading : pendingLoading

  if (error) {
    return <Box>{error.message}</Box>
  }

  return (
    <div className="-mt-8">
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        aria-label="invoice tabs">
        <Tab label="Complete" />
        <Tab label="Rejected" />
      </Tabs>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginY: '16px',
        }}>
        <Typography variant="h4">
          {selectedTab === 0 ? 'Recently Completed' : 'Recently Rejected'}
        </Typography>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          disabled={loading}
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder="Search for kitchens, suppliers, users"
          size="small"
          sx={{ width: '300px' }}
          value={searchTerm}
          variant="outlined"
        />
      </Box>
      <DataGrid
        slots={{
          noRowsOverlay: () => <NoRowsOverlay loading />,
        }}
        columns={selectedTab === 0 ? completeColumns : incompleteColumns}
        // @ts-ignore
        rows={rows}
        rowCount={(selectedTab === 0 ? invoiceCount : pendingCount) ?? -1}
        pageSizeOptions={[5, 10, 25, 100]}
        paginationModel={
          selectedTab === 0
            ? completePaginationModel
            : incompletePaginationModel
        }
        paginationMode="server"
        onPaginationModelChange={
          selectedTab === 0
            ? setCompletePaginationModel
            : setIncompletePaginationModel
        }
      />
    </div>
  )
}
