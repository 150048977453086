import { api } from 'api/rest'
import { TPendingInvoiceOverview } from 'app/types'

export default async function getInvoiceOverview(refresh: boolean) {
  try {
    const response = await api.get<TPendingInvoiceOverview>(
      `/invoices/pending/overview${refresh ? '?useCache=false' : ''}`,
    )
    return response.data
  } catch (error) {
    console.error('Error getting invoice overview', error)
    return null
  }
}
