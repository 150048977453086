import { gql } from '@apollo/client'

export const getInvoicesQuery = gql`
  query invoices(
    $where: InvoiceWhereInput!
    $orderBy: [InvoiceOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    invoicesCount(where: $where)
    invoices(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      _cursor
      id
      invoiceId
      invoiceDate
      invoiceTotal
      vat
      entries(skip: 0, take: 1000) {
        _cursor
        id
        packSize
        price
        productCode
        unitValue
        unit {
          _cursor
          id
          name
        }
        unit {
          _cursor
          id
          name
        }
        product {
          _cursor
          id
          name
        }
      }
      supplier {
        _cursor
        id
        name
      }
      kitchen {
        _cursor
        id
        name
      }
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
    }
  }
`


export const getPendingInvoicesQuery = gql`
  query pendingInvoices(
    $where: PendingInvoiceWhereInput
    $orderBy: [PendingInvoiceOrderByInput!]
    $take: Int
    $skip: Int
  ) {
    pendingInvoicesCount(where: $where)
    pendingInvoices(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      _cursor
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
      id
      signedImages(download: false)
      imagesProcessedSigned
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
      kitchen {
        _cursor
        id
        name
      }
    }
  }
`
