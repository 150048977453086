export const PRIMARY = '#20314a'
export const SECONDARY = '#f5d94e'
export const PRIMARY_DARK = '#91a1c3'
export const SECONDARY_DARK = '#e4b400'

export const jellyBlue = '#1F304A'
export const jellyRed = '#FF2B30'
export const jellyYellow = '#F5D94F'

export const DARK_GREY = '#4c596e'
export const GREY = '#a5acb7'
export const lightGrey = '#E4E6E9'
export const panelBackgroundColour = '#f5f5f5'

export const lightBlue = '#20314a'

// productcolours
export const highlightProduct = '#DBDEE2'
export const catalogUsed = '#abe0dd'
export const catalogNotUsed = '#59BAB7'
export const catalogNotUsedSelected = '#FFF'
export const customProductUsed = '#EBC1D8'
export const customProductNotUsed = '#682E58'
