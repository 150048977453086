import { API } from 'app'
import type {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  RawAxiosResponseHeaders,
  AxiosResponseHeaders,
} from 'axios'
import axios from 'axios'
import { clearCurrentUserToken } from 'utils'
import { tokenManager } from './TokenManager'

type ApiResponse<T> = {
  config: AxiosRequestConfig
  data: T
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders
  status: number
  statusText: string
}

class RestApi {
  private readonly axiosInstance: AxiosInstance

  public constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL: `${baseURL}/rest`,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        try {
          const token = await tokenManager.getValidToken()
          if (token) {
            config.headers.Authorization = `Bearer ${token}`
          }
          return config
        } catch (error) {
          this.redirectToLogin()
          return Promise.reject(error)
        }
      },
      (error) => {
        return Promise.reject(error)
      },
    )

    // Optional: Add a response interceptor to handle 401 errors
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response?.status === 401) {
          try {
            await tokenManager.getValidToken()
            // Retry the original request
            error.config.headers.Authorization = `Bearer ${tokenManager.getToken()}`
            return this.axiosInstance.request(error.config)
          } catch (e) {
            window.alert('Error refreshing token in REST: ' + JSON.stringify(e))
            this.redirectToLogin()
            return Promise.reject(e)
          }
        }
        return Promise.reject(error)
      },
    )
  }
  public async get<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(url, config)
    return this.buildApiResponse(response)
  }

  public async post<T>(
    url: string,
    data?: Record<string, unknown>,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response: AxiosResponse<T> = await this.axiosInstance.post(
      url,
      data,
      config,
    )
    return this.buildApiResponse(response)
  }

  public async put<T>(
    url: string,
    data?: Record<string, unknown>,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(
      url,
      data,
      config,
    )
    return this.buildApiResponse(response)
  }

  public async delete<T>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<ApiResponse<T>> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(
      url,
      config,
    )
    return this.buildApiResponse(response)
  }

  private buildApiResponse<T>(response: AxiosResponse<T>): ApiResponse<T> {
    return {
      config: response.config,
      data: response.data,
      headers: response.headers,
      status: response.status,
      statusText: response.statusText,
    }
  }

  private redirectToLogin() {
    clearCurrentUserToken()
    window.location.reload()
  }
}

export const api = new RestApi(API ?? '')
