import { DataGrid, GridToolbar } from '@mui/x-data-grid'

import type { Item } from './TSuggestion';
import { Divider, Switch } from '@mui/material';
import { stringCompare } from '../../Form/utils'
import { useInvoice } from 'context/InvoiceContext'

export function MagicOverview(): React.ReactElement {
  const { invoiceContext, setInvoiceContext } = useInvoice()
  // construct a datagrid for the items
  const { suggestion } = invoiceContext
  const { items } = suggestion ?? { items: [] }

  const columns = [
    { field: 'quantity', headerName: 'Qty', width: 50 },
    { field: 'code', headerName: 'Code' },
    { field: 'name', headerName: 'Extracted', width: 350 },
    { field: 'matched', headerName: 'Matched', width: 350 },
    { field: 'price', headerName: '£', width: 100},
    { field: 'confidence', headerName: 'Confidence', width: 100},
    { field: 'accuracy', headerName: 'Extraction Accuracy', width: 100},
  ]

  const rows = items.map((item, index) => {
    const { name, code, price, quantity, confidence, product } = item

    return {
      id: index,
      product,
      code,
      name: name.toUpperCase(),
      confidence: stringCompare(name.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '') || '', product?.name.toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '') || '').toFixed(2),
      quantity,
      price,
      accuracy: confidence.toFixed(2),
      matched: product?.name ?? 'No match found',
    }
  })

  const itemClick = (item: Item) => {
    setInvoiceContext({
      ...invoiceContext,
      queryItem: item,
    })
  }


  return (
    <div>

      <div className="flex justify-between">
      <InvoiceSuggestionOverview />
      <MagicControls />

      </div>
      <div className="h-144">
      <DataGrid
        onRowClick={(params) => itemClick(params.row.product)}
        rows={rows}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
        }}
      />
      </div>
    </div>
  )
}

function InvoiceSuggestionOverview() {
  const { invoiceContext } = useInvoice()
  const { suggestion, kitchen } = invoiceContext
  if(!suggestion || !kitchen || !suggestion.supplier || !suggestion.items || suggestion.items.length === 0) {
    return <div></div>
  }
  return (
    <div className="flex flex-col border p-2 rounded-xl mb-2">
      <div className="flex flex-col">
        <div className="flex justify-between font-bold">
          <h2 className="text-xl">{kitchen!.name} | {suggestion.supplier.name} | Overview</h2>
          <Divider />
        </div>
                <div className="flex justify-between h-8">
          <h2 className="text-lg">Rows:</h2>
          <h2 className="text-xl font-semibold">{suggestion.items.length}</h2>
        </div>
        <div className="flex justify-between h-8">
          <h2 className="text-lg">Total:</h2>
          <h2 className="text-xl font-semibold">£{suggestion.invoiceTotal}</h2>
        </div>
      </div>
    </div>
  )
}

function MagicControls() {
  const { invoiceContext, setInvoiceContext } = useInvoice()
  const { showConfidence, autoMagic, showIdLabels} = invoiceContext


    const handleToggleConfidence = (
  ) => {
    setInvoiceContext({
      ...invoiceContext,
      showConfidence: !showConfidence,
    })
  }

  const handleToggleAutoMagic = (
  ) => {
    setInvoiceContext({
      ...invoiceContext,
      autoMagic: !autoMagic,
    })
  }

  const handleToggleIdLabels = (
  ) => {
    setInvoiceContext({
      ...invoiceContext,
      showIdLabels: !showIdLabels,
    })
  }

  return (
    <div className="flex flex-col border p-2 rounded-xl mb-2">
      <div className="flex flex-col">
        <div className="flex justify-between font-bold">
          <h2 className="text-xl">Form Controls</h2>
          <Divider />
        </div>
        <div className="flex justify-between h-8">
          <h2 className="text-lg">Show Confidence Bubbles:</h2>
          <Switch checked={showConfidence} onChange={handleToggleConfidence} inputProps={{ 'aria-label': 'controlled' }} />
        </div>
        <div className="flex justify-between h-8">
          <h2 className="text-lg">Auto Magic:</h2>
                    <Switch checked={autoMagic} onChange={handleToggleAutoMagic} inputProps={{ 'aria-label': 'controlled' }} />
        </div>
        <div className="flex justify-between h-8">
          <h2 className="text-lg">Show ID Labels:</h2>
          <Switch checked={showIdLabels} onChange={handleToggleIdLabels} inputProps={{ 'aria-label': 'controlled' }} />
          </div>
      </div>
    </div>
  )
}
