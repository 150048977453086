import { useMutation, useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import type {
  Invoice,
  InvoiceEntry,
  Mutation,
  MutationUpdateOneInvoiceArgs,
  Query,
  QueryInvoiceArgs,
} from 'api'
import {
  Button,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from 'components'
import { MultiImageContainer } from 'components/MultiImageContainer'
import dayjs from 'dayjs'
import type { FormikErrors } from 'formik'
import { Formik } from 'formik'
import { isNil, without } from 'ramda'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { routes } from 'routes'
import { object, array, number, string } from 'yup'

import { getInvoiceByIdQuery, updateOneInvoiceMutation } from './graphql'

export function EditInvoice(): React.ReactElement {
  const { id } = useParams()
  const navigate = useNavigate()

  const { data, error, loading } = useQuery<
    { invoice: Query['invoice'] },
    QueryInvoiceArgs
  >(getInvoiceByIdQuery, {
    skip: isNil(id),
    variables: { where: { id: Number(id) } },
  })

  const [updateInvoice] = useMutation<
    { updateOneInvoice: Mutation['updateOneInvoice'] },
    MutationUpdateOneInvoiceArgs
  >(updateOneInvoiceMutation, {
    onCompleted: (data) => {
      if (data.updateOneInvoice) {
        toast.success('Successfully updated')

        navigate(
          routes.View.replace(':id', String(data.updateOneInvoice.id ?? '')),
        )
      }
    },
    onError: (error) => {
      console.log('error', error)
    },
  })

  if (loading) {
    return <Box>Loading</Box>
  }

  if (error) {
    return <Box>{error}</Box>
  }

  const {
    invoiceId,
    invoiceDate,
    invoiceTotal,
    vat,
    createdAt,
    createdBy,
    supplier,
    kitchen,
    entries,
    images,
    signedImages,
  } = data?.invoice as Invoice

  const initialValues = {
    entries,
    images: images ?? [],
    invoiceDate: dayjs(invoiceDate).format('YYYY-MM-DD'),
    invoiceId,
    invoiceTotal,
    vat,
  }

  const validationSchema = object({
    entries: array().of(object().shape({ price: number().required() })),
    invoiceDate: invoiceDate
      ? string().required('Required')
      : string().nullable(),
    invoiceId: invoiceId ? string().required('Required') : string().nullable(),
    invoiceTotal: number().nullable(),
    vat: number().nullable(),
  })

  type formFields = typeof initialValues

  const handleSubmit = (values: formFields) => {
    const { entries, invoiceDate, ...rest } = values

    void updateInvoice({
      variables: {
        data: {
          ...rest,
          entries: entries.map((entry) => ({
            id: entry.id,
            price: Number.parseFloat(String(entry.price)),
          })),
          invoiceDate: new Date(invoiceDate),
        },
        id: Number(id),
      },
    })
  }

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}>
        {(formik) => (
          <Box component="form">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}>
              <Box>
                <Typography variant="h5">{kitchen.name}</Typography>
                <Typography variant="h3">{supplier.name}</Typography>
              </Box>
              <Button
                color="primary"
                disabled={
                  (!formik.isValid && !formik.dirty) || formik.isSubmitting
                }
                onClick={() => {
                  formik.handleSubmit()
                }}
                sx={{ height: '33px', width: '80px' }}
                variant="contained">
                Save
              </Button>
            </Box>
            <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Invoice Id</TableCell>
                    <TableCell>Invoice Date</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>VAT</TableCell>
                    <TableCell>Entry Date</TableCell>
                    <TableCell>Entered By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key="invoice">
                    <TableCell
                      sx={{
                        padding: '8px 16px',
                      }}>
                      <TextField
                        error={!!formik.errors.invoiceId}
                        onChange={(event) => {
                          formik.setFieldValue('invoiceId', event.target.value)
                        }}
                        placeholder="Invoice ID"
                        size="small"
                        sx={{ minWidth: '80px' }}
                        value={formik.values.invoiceId}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '8px 16px',
                      }}>
                      <TextField
                        error={!!formik.errors.invoiceDate}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'invoiceDate',
                            event.target.value,
                          )
                        }}
                        placeholder="Invoice Date"
                        required={false}
                        size="small"
                        type="date"
                        value={formik.values.invoiceDate}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '8px 16px',
                      }}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ paddingRight: '8px' }}>
                              £
                            </InputAdornment>
                          ),
                        }}
                        error={!!formik.errors.invoiceTotal}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'invoiceTotal',
                            Number.parseFloat(event.target.value),
                          )
                        }}
                        placeholder="Total"
                        size="small"
                        sx={{ minWidth: '80px' }}
                        type="number"
                        value={formik.values.invoiceTotal}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '8px 16px',
                      }}>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              sx={{ paddingRight: '8px' }}>
                              £
                            </InputAdornment>
                          ),
                        }}
                        error={!!formik.errors.vat}
                        onChange={(event) => {
                          formik.setFieldValue(
                            'vat',
                            Number.parseFloat(event.target.value),
                          )
                        }}
                        placeholder="VAT"
                        size="small"
                        sx={{ minWidth: '80px' }}
                        type="number"
                        value={formik.values.vat}
                        variant="outlined"
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '8px 16px',
                      }}>
                      {dayjs(createdAt).format('DD/MM/YYYY HH:mm')}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: '8px 16px',
                      }}>{`${createdBy.firstName} ${createdBy.lastName}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell size="small">Product Code</TableCell>
                    <TableCell size="small">Product Name</TableCell>
                    <TableCell size="small">Quantity</TableCell>
                    <TableCell size="small">Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entries.map((entry, index) => (
                    <TableRow key={entry.id}>
                      <TableCell size="small">{entry.productCode}</TableCell>
                      <TableCell size="small">{entry.product?.name}</TableCell>
                      <TableCell size="small">
                        {entry.packSize} x {entry.unitValue}
                        {entry.unit.name}
                      </TableCell>
                      <TableCell size="small">
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                sx={{ paddingRight: '8px' }}>
                                £
                              </InputAdornment>
                            ),
                          }}
                          error={
                            formik.errors.entries
                              ? !!(
                                  formik.errors.entries[
                                    index
                                  ] as FormikErrors<InvoiceEntry>
                                ).price
                              : undefined
                          }
                          onChange={(event) => {
                            formik.setFieldValue(
                              `entries[${index}].price`,
                              event.target.value,
                            )
                          }}
                          placeholder="VAT"
                          size="small"
                          value={formik.values.entries[index].price}
                          variant="outlined"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {data?.invoice?.id && (
              <MultiImageContainer
                addImage={(value) => {
                  formik.setFieldValue('images', [
                    ...formik.values.images,
                    value,
                  ])
                }}
                altText={'Click to add a photo'}
                imageSource={undefined}
                images={formik.values.images}
                invoiceId={data.invoice.id}
                kitchenId={kitchen.id}
                loading={loading}
                mode="edit"
                removeImage={(value) => {
                  formik.setFieldValue(
                    'images',
                    without([value], formik.values.images),
                  )
                }}
                secure
                signedImages={signedImages}
                supplierId={supplier.id}
              />
            )}
          </Box>
        )}
      </Formik>
    </Box>
  )
}
