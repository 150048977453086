import { ApolloQueryResult, MutationFunctionOptions } from '@apollo/client';
import { Box } from '@mui/material';
import type { Kitchen, Mutation, MutationUpdateManyPendingInvoiceArgs, MutationUpdateOnePendingInvoiceArgs } from 'api';
import { TOAST_AUTOCLOSE } from 'app';
import { Button, KitchenSelect, Paper, Modal } from 'components';
import { toast } from 'react-toastify'


export type UpdateManyPendingInvoiceFunc = (
  options?: MutationFunctionOptions<
    { updateManyPendingInvoice: Mutation['updateManyPendingInvoice'] },
    MutationUpdateManyPendingInvoiceArgs
  >,
) => Promise<
  ApolloQueryResult<{
    updateManyPendingInvoice: Mutation['updateManyPendingInvoice']
  }>
>

export type UpdateOnePendingInvoiceFunc = (
  options?: MutationFunctionOptions<
    { updateOnePendingInvoice: Mutation['updateOnePendingInvoice'] },
    MutationUpdateOnePendingInvoiceArgs
  >,
) => Promise<
  ApolloQueryResult<{
    updateOnePendingInvoice: Mutation['updateOnePendingInvoice']
  }>
>


export default function MoveInvoicesModal({
setModal,
modal,
selected,
selectedKitchen,
setKitchen,
updateManyPendingInvoice,
kitchen,
setSelected,
}: {
setModal: (value: React.SetStateAction<boolean>) => void
modal: boolean
selected: number[]
selectedKitchen: Kitchen | undefined
setKitchen: (value: React.SetStateAction<number | undefined>) => void
updateManyPendingInvoice: UpdateManyPendingInvoiceFunc 
kitchen: number | undefined
setSelected: (value: React.SetStateAction<number[]>) => void
}) { 
    return (
              <Modal
        onClick={() => {
          setModal(false)
        }}
        open={modal}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '40px',
          }}
        >
          <Box>
            Move {selected.length} invoice(s) from {selectedKitchen?.name} to
          </Box>
          <KitchenSelect
            onChange={(kitchen) => {
              setKitchen(kitchen?.id)
            }}
          />
          <Button
            onClick={() => {
              setModal(false)
            }}
          >
            CLOSE
          </Button>
          <Button
            onClick={async () => {
              const res = await updateManyPendingInvoice({
                variables: {
                  data: {
                    kitchenId: kitchen,
                  },
                  where: selected.map((id) => ({ id })),
                },
              })

              if (res.data?.updateManyPendingInvoice) {
                toast.success(
                  `Moved ${
                    res.data.updateManyPendingInvoice
                  } invoice(s) from ${String(selectedKitchen?.name)}`,
                  {
                    autoClose: TOAST_AUTOCLOSE,
                    style: {
                      marginTop: '110px',
                    },
                  }
                )
              }
              setSelected([])
              setModal(false)
              // @ts-expect-error No need to pass undefined
              setKitchen()
            }}
          >
            CONFIRM MOVE
          </Button>
        </Paper>
      </Modal>
    )
}