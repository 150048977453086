import { History, SsidChart } from "@mui/icons-material";
import { CloudUploadIcon, HomeIcon } from "components";
import { routes } from "routes";

export const menu = [
  {
    icon: <HomeIcon />,
    label: 'Home',
    route: routes.Home,
  },
  {
    icon: <History />,
    label: 'View Invoices',
    route: routes.List,
  },
  {
    icon: <CloudUploadIcon />,
    label: 'Upload Invoices',
    route: routes.Upload,
  },
  {
    icon: <SsidChart />,
    label: 'Analysis',
    route: routes.Analysis,
  }
]