import { Box, useTheme } from '@mui/material'
import { VERSION } from 'app'
import { menu } from 'app/nav'
import {
  AppBar,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuIcon,
  Toolbar,
  Typography,
} from 'components'
import { useInvoice } from 'context/InvoiceContext'
// import { DarkModeToggle } from 'components/DarkModeToggle'
import { Suspense, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { InvoiceSettings } from 'screens/Dashboard/components/InvoiceSettings'
import { clearCurrentUserToken } from 'utils'

export function Default(): React.ReactElement {
  const navigate = useNavigate()
  const theme = useTheme()
  const { invoiceContext } = useInvoice()
  const { kitchen } = invoiceContext

  const handleOnClick = () => {
    clearCurrentUserToken()
    navigate('/')
  }

  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(!open)
  }
  const drawerWidth = 240

  return (
    <Box
      sx={{
        display: 'flex',
      }}>
      <AppBar
        position="fixed"
        sx={{
          marginLeft: open ? drawerWidth : undefined,
          transition: theme.transitions.create(['width', 'margin'], {
            duration: open
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          }),
          width: open ? `calc(100% - ${drawerWidth}px)` : undefined,
          zIndex: theme.zIndex.drawer + 1,
        }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{
              display: open ? 'none' : undefined,
              marginRight: 3,
            }}>
            <MenuIcon />
          </IconButton>
          <Typography
            color="inherit"
            component="h1"
            noWrap
            className="flex"
            sx={{
              flexGrow: 1,
            }}
            variant="h6">
            Jelly Invoice&nbsp;<span>v{VERSION}</span>{' '}
            {kitchen && (
              <a href={`/upload/${kitchen.id}`}>
                <div className="text-secondary">&nbsp;| {kitchen.name}</div>
              </a>
            )}
          </Typography>

          {/* <DarkModeToggle /> */}
          <InvoiceSettings />
          <Button
            sx={{
              fontFamily: 'Rubik, sans-serif',
              fontSize: 14,
              fontWeight: 600,
              letterSpacing: 1.25,
              height: '37px',
            }}
            color="secondary"
            onClick={handleOnClick}
            variant="outlined">
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        PaperProps={{
          sx: {
            transition: theme.transitions.create('width', {
              duration: open
                ? theme.transitions.duration.enteringScreen
                : theme.transitions.duration.leavingScreen,
              easing: theme.transitions.easing.sharp,
            }),
            width: {
              lg: open ? drawerWidth : '55px',
              md: open ? drawerWidth : '55px',
              sm: open ? drawerWidth : '55px',
              xl: open ? drawerWidth : '55px',
              xs: open ? drawerWidth : 0,
            },
          },
        }}
        sx={{
          flexShrink: 0,
          overflowX: open ? undefined : 'hidden',
          transition: theme.transitions.create('width', {
            duration: open
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          }),
          whiteSpace: 'nowrap',
          width: {
            lg: open ? drawerWidth : '55px',
            md: open ? drawerWidth : '55px',
            sm: open ? drawerWidth : '55px',
            xl: open ? drawerWidth : '55px',
            xs: open ? drawerWidth : 0,
          },
        }}
        variant="permanent">
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            color="inherit"
            edge="start"
            onClick={handleDrawerOpen}
            sx={{
              marginRight: '3px',
            }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List dense>
          {menu.map((item) => (
            <ListItem
              button
              dense
              key={item.label}
              onClick={() => {
                navigate(item.route)
                setOpen(false)
              }}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: theme.spacing(2),
        }}>
        <Box sx={theme.mixins.toolbar} />
        <Box sx={{}}>
          <Suspense fallback={<CircularProgress />}>
            <Outlet />
          </Suspense>
        </Box>
      </Box>
    </Box>
  )
}
