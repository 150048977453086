import type { Unit, Kitchen } from "api"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import type { Item, TSuggestion } from "screens/Dashboard/components/Buttons/MagicButton/TSuggestion"

export type TInvoiceContext = {
    showConfidence: boolean
    autoMagic: boolean
    showIdLabels: boolean
    showAllKitchens: boolean
    kitchen: Kitchen | null
    suggestion: TSuggestion | null
    queryItem: Item | null
    units: Unit[] | null
}

type InvoiceContextType = {
    invoiceContext: TInvoiceContext
    setInvoiceContext: (value: TInvoiceContext) => void
}

const InvoiceContext = createContext<InvoiceContextType | undefined>(undefined)

export const useInvoice = (): InvoiceContextType => {
    const context = useContext(InvoiceContext)
    if (context === undefined) {
        throw new Error('useInvoice must be used within a InvoiceProvider')
    }

    return context


}

const getInitialContext = (): TInvoiceContext => {
    return {
        showConfidence: JSON.parse(localStorage.getItem('showConfidence') ?? 'true'),
        autoMagic: JSON.parse(localStorage.getItem('autoMagic') ?? 'true'),
        showIdLabels: JSON.parse(localStorage.getItem('showIdLabels') ?? 'true'),
        showAllKitchens: JSON.parse(localStorage.getItem('showAllKitchens') ?? 'true'),
        kitchen: null,
        suggestion: null,
        queryItem: null,
        units: null,
    }
}

export function InvoiceProvider({ children }: { children: React.ReactNode }): React.ReactElement{
    const [invoiceContext, setInvoiceContext] = useState<TInvoiceContext>(getInitialContext())

        useEffect(() => {
        localStorage.setItem('showConfidence', JSON.stringify(invoiceContext.showConfidence))
    }, [invoiceContext.showConfidence])

    useEffect(() => {
        localStorage.setItem('autoMagic', JSON.stringify(invoiceContext.autoMagic))
    }, [invoiceContext.autoMagic])

    useEffect(() => {
        localStorage.setItem('showIdLabels', JSON.stringify(invoiceContext.showIdLabels))
    }
    , [invoiceContext.showIdLabels])

    useEffect(() => {
        localStorage.setItem('showAllKitchens', JSON.stringify(invoiceContext.showAllKitchens))
    }
    , [invoiceContext.showAllKitchens])

      const value = useMemo(() => ({ invoiceContext, setInvoiceContext }), [invoiceContext])


    return (
        <InvoiceContext.Provider value={value}>
            {children}
        </InvoiceContext.Provider>
    )
}

