import nodePackage from '../../package.json'

export const API = process.env.REACT_APP_API
export const VERSION = nodePackage.version

export const CONFIDENCE_THRESHOLD = 90
export const VARIANCE_THRESHOLD = 30

export const INITIAL_PRODUCT = {
  code: '',
  confidence: 100,
  conversionUnit: undefined,
  conversionUnitType: undefined,
  conversionUnitValue: undefined,
  create: false,
  id: 0,
  name: '',
  packSize: undefined,
  price: undefined,
  productId: undefined,
  quantity: undefined,
  save: false,
  total: undefined,
  unit: undefined,
  unitValue: undefined,
  updatedAt: new Date(),
  updatedBy: {
    firstName: '',
    id: -1,
    lastName: '',
  },
  wastage: 0,
}

export const INITIAL_VALUES = {
  invoice: {
    invoiceDate: '',
    invoiceDateConfidence: 100,
    invoiceID: '',
    invoiceIDConfidence: 100,
    total: undefined,
    totalConfidence: 100,
    vatTotal: undefined,
    vatTotalConfidence: 100,
  },
  kitchen: { label: '', value: 0 },
  products: [],
  supplier: { label: '', value: 0, confidence: 100, isKitchenMatch: false, real: false },
}

export const CHAR_WIDTH = 19
export const DEFAULT_LENGTH = 4


export const UNIT_ID_MAP: { [key: string]: number } = {
    'g': 1,
    'kg': 2,
    'oz': 3,
    'lb': 4,
    'oz (us)': 5,
    'ml': 6,
    'cl': 7,
    'l': 8,
    'cup (us)': 9,
    'pint (us)': 10,
    'quart (us)': 11,
    'gal (us)': 12,
    'unit': 13,
    'portion': 14,
    'box': 15,
    'each': 16,
    'tub': 17,
    'bag': 18,
    'pack': 19,
    'case': 20,
    'bunch': 21,
    'jar': 22,
    'punnet': 23,
    'packet': 24,
    'tin': 25,
    'tray': 26,
    'piece': 27,
    'bottle': 28,
    'single': 29
};

export const TOAST_AUTOCLOSE = 1150

export const DEFAULT_BUTTON_STYLE = {
    fontFamily: 'Rubik, sans-serif',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.25,
    paddingX: '10px',
    height: '37px',
  }

export const DEFAULT_MODAL_STYLE = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: 2
};

