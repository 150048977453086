import { Box } from '@mui/material';
import { InvoiceStatus } from 'api'
import type { PendingInvoice, MutationUpdateOnePendingInvoiceArgs } from 'api';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes'
import { GREY, lightBlue } from 'styles/colors'

export default function PendingInvoiceActions({pendingInvoice, updateInvoice}: {
  pendingInvoice: PendingInvoice,
updateInvoice: (params: {
    variables: MutationUpdateOnePendingInvoiceArgs;
}) => void
}) {
    const navigate = useNavigate()
    return pendingInvoice.invoice ? 
        (
        <>
          <Button
            color="primary"
            onClick={() => {
              navigate(routes.View.replace(':id', String(pendingInvoice.invoice?.id)))
            }}
            sx={{ color: lightBlue, fontWeight: 'bold' }}
            type="button"
          >
            VIEW
          </Button>
          <Button
            onClick={() => {
              navigate(routes.Edit.replace(':id', String(pendingInvoice.invoice?.id)))
            }}
            sx={{ color: lightBlue, fontWeight: 'bold' }}
            type="button"
          >
            EDIT
          </Button>
        </>
      ) : pendingInvoice.status === InvoiceStatus.Pending ? (
        <>
          <Button
            color="primary"
            onClick={() => {
              navigate(`${routes.Home}/${pendingInvoice.id}`)
            }}
            type="button"
          >
            Process
          </Button>
          <Button
            color="primary"
            onClick={() => {
              void updateInvoice({
                variables: {
                  data: {
                    isMulti: !pendingInvoice.isMulti,
                  },
                  id: pendingInvoice.id,
                },
              })
            }}
            type="button"
          >
            {pendingInvoice.isMulti ? 'Set to single' : 'Set to multi'}
          </Button>
        </>
      ) : (
        <Box>
          <Box component="span" sx={{
                  color: GREY,
                }}>
            LOCKED
          </Box>
          <Button
            color="primary"
            onClick={() => {
              updateInvoice({
                variables: {
                  data: {
                    status: InvoiceStatus.Pending,
                  },
                  id: pendingInvoice.id,
                },
              })
            }}
            sx={{ color: '#ff0000', fontWeight: 'bold' }}
            type="button"
          >
            Revert to Pending
          </Button>
        </Box>
      )
}