import { useMutation } from '@apollo/client'
import { Box, Typography } from '@mui/material'
import {
  InvoiceStatus,
  MutationUpdateOnePendingInvoiceArgs,
  PendingInvoice,
} from 'api'
import { Button } from 'components'
import Modal from '@mui/material/Modal'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { routes } from 'routes'
import { updateOnePendingInvoice } from 'screens/Upload/graphql'
import { DEFAULT_BUTTON_STYLE, DEFAULT_MODAL_STYLE } from 'app'

export default function RevertButton({
  pendingInvoiceId,
}: {
  pendingInvoiceId: number
}) {
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false)

  const [updateOne, { loading: processLoading }] = useMutation<
    {
      updateOnePendingInvoice: PendingInvoice
    },
    MutationUpdateOnePendingInvoiceArgs
  >(updateOnePendingInvoice, {
    onCompleted: () => {
      toast.success(`Removed rejected status from invoice`)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const revertInvoice = async () => {
    await updateOne({
      variables: {
        data: {
          status: InvoiceStatus.Pending,
          processedAt: null,
          processedById: null,
        },
        id: pendingInvoiceId,
      },
    })
    setTimeout(() => {
      navigate(routes.Process + pendingInvoiceId)
    }, 500)
  }

  const handleConfirm = () => {
    revertInvoice()
    setModalOpen(false)
  }

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        onClick={() => {
          setModalOpen(true)
        }}>
        Reprocess
      </Button>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="reprocess-confirmation-title"
        aria-describedby="reprocess-confirmation-description">
        <Box sx={DEFAULT_MODAL_STYLE}>
          <Typography id="reprocess-confirmation-title" variant="h5">
            Confirm Reprocess
          </Typography>
          <div className="w-full my-4 border-t border-primary"></div>
          <Typography id="reprocess-confirmation-description" variant="h6">
            Are you sure you want to reprocess this invoice?<br></br>
            <span className="font-bold">This action cannot be undone.</span>
          </Typography>
          <div className="w-full my-4 border-t border-primary"></div>
          <div className="flex w-full justify-between mt-4">
            <Button
              variant="outlined"
              color="primary"
              sx={DEFAULT_BUTTON_STYLE}
              onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              sx={DEFAULT_BUTTON_STYLE}
              disabled={processLoading}>
              REVERT & REPROCESS
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  )
}
