import { gql } from '@apollo/client'

export const updateOnePendingInvoice = gql`
  mutation updateOnePendingInvoice(
    $data: PendingUpdateOneInvoiceInputObject!
    $id: Int!
  ) {
    updateOnePendingInvoice(data: $data, id: $id) {
      _cursor
      createdAt
      id
      images
      updatedAt
      status
      rejectionReason
      rejectionReasonNotes
      isMulti
    }
  }
`

export const deleteOnePendingInvoiceMutation = gql`
  mutation deleteOnePendingInvoice($id: Int!) {
    deleteOnePendingInvoice(id: $id) {
      _cursor
      createdAt
      id
    }
  }
`

export const deleteManyPendingInvoiceMutation = gql`
  mutation deleteManyPendingInvoice($ids: [Int!]!) {
    deleteManyPendingInvoice(ids: $ids)
  }
`

export const updateManyPendingInvoiceMutation = gql`
  mutation updateManyPendingInvoice(
    $data: PendingUpdateOneInvoiceInputObject!
    $where: [PendingInvoiceWhereUniqueInput!]!
  ) {
    updateManyPendingInvoice(data: $data, where: $where)
  }
`

export const signMultipleURLS = gql`
  query urls($urls: [SignedS3Input]) {
    MultipleSignedS3Urls(urls: $urls) {
      unsignedURL
      signedURL
    }
  }
`

export const createMultiplePendingInvoice = gql`
  mutation createMultiplePendingInvoice(
    $imageUrls: [String]!
    $kitchenId: Int!
  ) {
    createMultiplePendingInvoice(imageUrls: $imageUrls, kitchenId: $kitchenId) {
      _cursor
      createdAt
      id
      images
      kitchen {
        _cursor
        id
        name
      }
      updatedAt
      status
      rejectionReason
      rejectionReasonNotes
    }
  }
`

export const pendingInvoices = gql`
  query pendingInvoices(
    $where: PendingInvoiceWhereInput
    $skip: Int
    $take: Int
    $orderBy: [PendingInvoiceOrderByInput!]
  ) {
    pendingInvoicesCount(where: $where)
    pendingInvoices(
      where: $where
      orderBy: $orderBy
      skip: $skip
      take: $take
    ) {
      _cursor
      createdAt
      createdBy {
        _cursor
        id
        firstName
        lastName
      }
      id
      signedImages(download: false)
      imagesProcessedSigned
      suggestions
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
      invoice {
        _cursor
        id
        supplier {
          _cursor
          id
          name
        }
        createdBy {
          _cursor
          id
          firstName
          lastName
        }
        updatedBy {
          _cursor
          id
          firstName
          lastName
        }
        invoiceDate
        invoiceId
        invoiceTotal
        vat
      }
      isMulti
    }
  }
`

export const mergePendingInvoices = gql`
  mutation mergePendingInvoices($ids: [Int!]!) {
    mergePendingInvoices(ids: $ids) {
      _cursor
      createdAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      id
      images
      kitchen {
        _cursor
        id
      }
      updatedAt
      updatedBy {
        _cursor
        id
        firstName
        lastName
      }
      status
      rejectionReason
      rejectionReasonNotes
    }
  }
`
