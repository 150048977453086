import { init } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { VERSION } from 'app'
import React from 'react'
import ReactDOM from 'react-dom'

import { Container } from './Container'
import { reportWebVitals } from './reportWebVitals'
import { unregister } from './serviceWorkerRegistration'
import 'styles.css'

init({
  dsn: 'https://2637e8e174504c8792d133d7b65fd152@o174489.ingest.sentry.io/5431353',
  enabled: process.env.REACT_APP_SENTRY_ENV === ('production' || 'staging'),
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [new Integrations.BrowserTracing()],
  release: `invoice-ui@${VERSION}`,
  tracesSampleRate: 0.01,
})

if (process.env.NODE_ENV === 'development') {
  const axe = require('react-axe')

  axe(React, ReactDOM, 1000)
}

ReactDOM.render(
  <React.StrictMode>
    <Container />
  </React.StrictMode>,
  document.querySelector('#root'),
)

unregister()
reportWebVitals()
