import AutorenewIcon from '@mui/icons-material/Autorenew'
import CropFreeIcon from '@mui/icons-material/CropFree'
import RemoveIcon from '@mui/icons-material/Remove'
import { Box, IconButton } from '@mui/material'
import { useState } from 'react'
import type { ResizeCallbackData } from 'react-resizable'
import { Resizable } from 'react-resizable'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { resizeUrl } from 'utils/image'

import './resize.css'

import { AddIcon, OpenInNewIcon } from '../Icon'
import { Gradient } from '@mui/icons-material'

type Props = {
  images: InvoiceImage[]
}

export type InvoiceImage = {
  url: string
  type: 'image' | 'pdf'
  processedUrl?: string | null
}

export function ImageViewer({ images }: Props): React.ReactElement {
  const [image, setImage] = useState<InvoiceImage | undefined>(
    images[0] ?? undefined,
  )
  const [optimized, setOptimized] = useState(false)
  const [imageRotation, setImageRotation] = useState(0)
  const [height, setHeight] = useState(window.innerHeight * 0.7)
  const [width, setWidth] = useState(window.innerWidth * 0.8)

  const handleResize = (
    _event: React.SyntheticEvent,
    { size }: ResizeCallbackData,
  ) => {
    setHeight(size.height)
    setWidth(size.width)
  }

  const toggleOptimized = () => {
    setOptimized(!optimized)
  }

  const imageHeight = height * 0.8
  const imageWidth = width * 0.4

  return (
    <Box sx={{ position: 'sticky', top: '100px' }}>
      <Resizable
        axis="both"
        height={height}
        minConstraints={[0, 0]}
        onResize={handleResize}
        resizeHandles={['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
        width={width}>
        <Box
          sx={{
            backgroundColor: 'rgb(10,16,25)',
            marginLeft: '16px',
          }}>
          <Images
            handleSelect={(img: InvoiceImage) => {
              setImage(img)
            }}
            images={images}
          />

          {!!image && (
            <Box
              key={image.url}
              role="button"
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '80%',
                justifyContent: 'center',
                marginTop: '16px',
                position: 'relative',
              }}>
              {image.type === 'pdf' ? (
                <>
                  <Box
                    component="embed"
                    height={imageHeight}
                    src={image.url}
                    sx={{
                      height: imageHeight,
                      objectFit: 'contain',
                      paddingLeft: '40px',
                      paddingRight: '40px',
                      width: imageWidth,
                    }}
                    type="application/pdf"
                    width={imageWidth}
                  />
                  <IconButton
                    edge="start"
                    onClick={(e) => {
                      e.preventDefault()
                      window.open(image.url, '_blank')
                    }}
                    sx={{
                      background: '#E4E6E9',
                      borderRadius: '4px',
                      height: '46px',
                      marginBottom: '8px',
                      marginLeft: '2px',
                      marginRight: '2px',
                      marginTop: '10px',
                      width: '50px',
                    }}>
                    <OpenInNewIcon
                      sx={{
                        color: '#17243A',
                      }}
                    />
                  </IconButton>
                </>
              ) : (
                <TransformWrapper
                  defaultPositionX={300}
                  defaultPositionY={200}
                  defaultScale={1}>
                  {/* @ts-expect-error Dont define props */}
                  {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <>
                      <TransformComponent>
                        {}
                        <Box
                          sx={{
                            backgroundImage: `url(${
                              optimized ? image.processedUrl : image.url
                            })`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            cursor: 'grab',
                            height: imageHeight,
                            overflow: 'hidden',
                            transform: `rotate(${imageRotation}deg)`,
                            width: imageWidth,
                          }}
                        />
                      </TransformComponent>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}>
                        { image.processedUrl && (
                        <IconButton
                          edge="start"
                          onClick={(e) => {
                            e.preventDefault()
                            toggleOptimized()
                          }}
                          sx={{
                            background: optimized ? '#8f8f8f' : '#E4E6E9',
                            borderRadius: '4px',
                            height: '46px',
                            marginBottom: '8px',
                            marginLeft: '2px',
                            marginRight: '2px',
                            marginTop: '10px',
                            width: '50px',
                            '&:hover': {
                              background: optimized ? '#E4E6E9' : '#8f8f8f',
                            },
                          }}>
                          <Gradient
                            sx={{
                              color: '#17243A',
                            }}
                          />
                        </IconButton>)}
                        <IconButton
                          edge="start"
                          onClick={(e) => {
                            e.preventDefault()
                            resetTransform()
                            setImageRotation(0)
                          }}
                          sx={{
                            background: '#E4E6E9',
                            borderRadius: '4px',
                            height: '46px',
                            marginBottom: '8px',
                            marginLeft: '2px',
                            marginRight: '2px',
                            marginTop: '10px',
                            width: '50px',
                          }}>
                          <CropFreeIcon
                            sx={{
                              color: '#17243A',
                            }}
                          />
                        </IconButton>

                        <IconButton
                          edge="start"
                          onClick={(e) => {
                            e.preventDefault()
                            if (imageRotation + 90 >= 360) {
                              setImageRotation(0)
                            } else {
                              setImageRotation(imageRotation + 90)
                            }
                          }}
                          sx={{
                            background: '#E4E6E9',
                            borderRadius: '4px',
                            height: '46px',
                            marginBottom: '8px',
                            marginLeft: '2px',
                            marginRight: '2px',
                            marginTop: '10px',
                            width: '50px',
                          }}>
                          <AutorenewIcon
                            sx={{
                              color: '#17243A',
                            }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          onClick={(e) => {
                            e.preventDefault()
                            zoomOut(e)
                          }}
                          sx={{
                            background: '#E4E6E9',
                            borderRadius: '4px',
                            height: '46px',
                            marginBottom: '8px',
                            marginLeft: '2px',
                            marginRight: '2px',
                            marginTop: '10px',
                            width: '50px',
                          }}>
                          <RemoveIcon
                            sx={{
                              color: '#17243A',
                            }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          onClick={(e) => {
                            e.preventDefault()
                            zoomIn(e)
                          }}
                          sx={{
                            background: '#E4E6E9',
                            borderRadius: '4px',
                            height: '46px',
                            marginBottom: '8px',
                            marginLeft: '2px',
                            marginRight: '2px',
                            marginTop: '10px',
                            width: '50px',
                          }}>
                          <AddIcon
                            sx={{
                              color: '#17243A',
                            }}
                          />
                        </IconButton>
                        <IconButton
                          edge="start"
                          onClick={(e) => {
                            e.preventDefault()
                            window.open(image.url, '_blank')
                          }}
                          sx={{
                            background: '#E4E6E9',
                            borderRadius: '4px',
                            height: '46px',
                            marginBottom: '8px',
                            marginLeft: '2px',
                            marginRight: '2px',
                            marginTop: '10px',
                            width: '50px',
                          }}>
                          <OpenInNewIcon
                            sx={{
                              color: '#17243A',
                            }}
                          />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </TransformWrapper>
              )}
            </Box>
          )}
        </Box>
      </Resizable>
    </Box>
  )
}

function Images({
  images,
  handleSelect,
}: {
  images: InvoiceImage[]
  handleSelect: (img: InvoiceImage) => void
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
        width: '100%',
      }}>
      {images.map((image) => {
        return (
          <Box
            key={image.url}
            role="button"
            sx={{
              alignItems: 'center',
              borderColor: 'white',
              borderStyle: 'solid',
              borderWidth: '1px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginLeft: '24px',
              marginTop: '8px',
              position: 'relative',
            }}>
            {image.type === 'pdf' ? (
              <>
                <Box
                  component="embed"
                  height="80"
                  src={image.url}
                  sx={{
                    // filter:
                    height: '80px',
                    objectFit: 'cover',
                    width: '80px',
                  }}
                  type="application/pdf"
                  width="80"
                />
                <Box
                  onClick={() => {
                    handleSelect(image)
                  }}
                  sx={{
                    cursor: 'pointer',
                    height: '80px',
                    position: 'absolute',
                    top: 0,
                    width: '80px',
                    zIndex: 9999,
                  }}
                />
              </>
            ) : (
              <img
                onClick={() => {
                  handleSelect(image)
                }}
                src={resizeUrl(image.url, 80, 80)}
                style={{
                  cursor: 'pointer',
                  height: '80px',
                  objectFit: 'cover',
                  width: '80px',
                }}
              />
            )}
          </Box>
        )
      })}
    </Box>
  )
}