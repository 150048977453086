import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import type { Query, QueryPendingInvoicesArgs } from 'api'
import { InvoiceStatus } from 'api'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes'

import { pendingInvoicesCheck } from './graphql/query/pendingInvoice'

export function Queue(): JSX.Element | null {
  const { id: kitchenId } = useParams()
  const navigate = useNavigate()

  if (!kitchenId) {
    navigate(routes.Upload)
  }

  const { data, loading } = useQuery<
    {
      pendingInvoices: Query['pendingInvoices']
    },
    QueryPendingInvoicesArgs
  >(pendingInvoicesCheck, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        kitchenId: {
          equals: Number(kitchenId),
        },
        status: {
          equals: InvoiceStatus.Pending,
        },
      },
    },
  })

  if (loading) {
    return <Box>Loading</Box>
  }

  if (data?.pendingInvoices && data.pendingInvoices.length > 0) {
    // if there are multi invoices left, go to the first one
    const notMultiInvoices = data.pendingInvoices.filter(
      (invoice) => !invoice.isMulti,
    )
    if (notMultiInvoices.length > 0) {
      navigate(`${routes.Process}${notMultiInvoices[0].id}`)
      return <Box>Redirecting</Box>
    } else {
      navigate(routes.UploadWithKitchen.replace(':id', kitchenId))
      return null
    }
  }

  navigate(routes.Home)
  return null
}
