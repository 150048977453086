import { Box, Button, Modal } from '@mui/material';


import { useState } from 'react'
import { MagicOverview } from './Buttons/MagicButton/MagicOverview';


export function InvoiceSettings(): React.ReactElement {
  const [showSetupModal, setShowSetupModal] = useState(false)




  const handleToggleSetupModal = (
  ) => {
    setShowSetupModal(!showSetupModal)
  }

  return (
    <div className='mx-2'>
      <Modal className='mt-4' open={showSetupModal} onClose={handleToggleSetupModal}>
        <Box
          sx={{
            p: 1,
            backgroundColor: 'white',
            margin: 'auto',
            borderRadius: 2,
            width: '80%',
          }}>
          <MagicOverview />
        </Box>
      </Modal>
      <div className="flex">
          <Button
            size={'small'}
            onClick={handleToggleSetupModal}
            color={'secondary'}
            variant='outlined'
            sx={{
                  fontFamily: 'Rubik, sans-serif',
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 1.25,
    height: '37px'
            }}>
            settings
          </Button>
      </div>
    </div>
  )
}
