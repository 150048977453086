import { Box } from "@mui/material"
import { Kitchen } from "api"
import { Button, CloudUploadIcon } from "components"
import { GREY, lightBlue } from "styles/colors"

export default function DragDropUpload({ selectedKitchen, setFiles, inputRef }:
    { selectedKitchen: Kitchen | null, setFiles: (files: File[]) => void, inputRef: React.RefObject<HTMLInputElement> }) { 
    return selectedKitchen ? ( 
                      <>
                <Button
                  color="primary"
                  disabled={!selectedKitchen}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) => {
                    e.stopPropagation()
                    // @ts-expect-error Ref exists
                    inputRef.current.click()
                  }}
                  sx={{
                    color: lightBlue,
                    fontWeight: 600,
                    paddingRight: '20px',
                    border: '1px solid #2D9CDB',
                    borderRadius: '5px',
                    padding: '5px 25px',
                    marginRight: '10px',
                  }}
                  type="button"
                >
                  <CloudUploadIcon sx={{ marginRight: '10px' }} />
                  Upload New Invoices
                </Button>
                <input
                  accept="image/*,application/pdf"
                  autoComplete="off"
                  multiple
                  onChange={({ target }) => {
                    // attempt to fix empty filelist object
                    const f = []
                    for (let i = 0; i < (target.files?.length ?? 0); i++) {
                      // @ts-expect-error Files has to exist with length
                      f.push(target.files[i])
                    }
                    setFiles(f)
                  }}
                  ref={inputRef}
                  style={{
                    height: 0,
                    left: 0,
                    opacity: 0,
                    position: 'absolute',
                    width: 0,
                  }}
                  type="file"
                  value=""
                />
                <Box
                  sx={{
                    alignItems: 'center',
                    border: `1px dashed ${GREY}`,
                    px: 2,
                    borderRadius: '5px',
                    color: GREY,
                    display: 'flex',
                    fontWeight: 600,
                    height: '60%',
                    justifyContent: 'center',
                    width: '40%',
                  }}
                >
                  {' '}
                  Or drag and drop files here
                </Box>
              </>
    ) : null
}