import { ChevronRight, ExpandMore, Refresh } from '@mui/icons-material';
import { FormControl, FormControlLabel, Popover, Radio, RadioGroup } from '@mui/material';
import { Button } from 'components'
import { useInvoice } from 'context/InvoiceContext'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { routes } from 'routes'
import { SECONDARY } from 'styles/colors'

export default function DailyOverview({
  refreshHandler,
}: {
  refreshHandler: () => void
}) {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const { invoiceContext, setInvoiceContext } = useInvoice()
  const { showAllKitchens } = invoiceContext

  const setShowAllKitchens = (showAllKitchens: boolean) => {
    setInvoiceContext({ ...invoiceContext, showAllKitchens })
  }

  const open = Boolean(anchorEl)
  return (
    <div className="border rounded-xl px-4 py-2 w-max">
      <div className="flex">
        <p className="w-full flex justify-start font-bold text-xl">
          Welcome Back | Overview
        </p>
      </div>
      <div className="">
        {/* show all invoices switch */}
        <div className="flex space-x-4 mt-4">
          <Button
            color="primary"
            disabled={false}
            onClick={handleOpen}
            type="button"
            variant="outlined">
            {showAllKitchens ? 'All Kitchens' : 'Paid Kitchens'}

            <ExpandMore sx={{ marginLeft: '2px' }} />
          </Button>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null)
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <RadioGroup className="p-4">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={showAllKitchens}
                  onChange={(_event, value) => {
                    setShowAllKitchens(value === 'true')
                  }}
                  name="radio-buttons-group">
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="All Kitchens"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Only Paid Kitchens"
                  />
                </RadioGroup>
              </FormControl>
            </RadioGroup>
          </Popover>

          <Button
            color="primary"
            disabled={false}
            onClick={refreshHandler}
            type="button"
            variant="contained"
            sx={{ color: SECONDARY }}>
            Refresh <Refresh sx={{ marginLeft: '2px' }} />
          </Button>

          <Button
            color="primary"
            disabled={false}
            onClick={() => {
              navigate(routes.Upload)
            }}
            type="button"
            variant="contained"
            sx={{ color: SECONDARY }}>
            Add Invoices <ChevronRight sx={{}} />
          </Button>
        </div>
      </div>
    </div>
  )
}
