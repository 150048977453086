import { SsidChart } from '@mui/icons-material'
import { DashboardIcon } from 'components'
import { Default as DefaultLayout, WideLayout } from 'layouts'
import { lazy } from 'react'
import { AnalysisScreen } from 'screens/Analysis/AnalysisScreen'

import { Queue } from '../screens/Dashboard/Queue'
import { EditInvoice } from '../screens/Invoices/Edit'
import { ListInvoice } from '../screens/Invoices/List/HistoryList'
import { ViewInvoice } from '../screens/Invoices/View'
import { KitchenInvoiceList } from '../screens/Kitchens/List/KitchenList'
import { Upload } from '../screens/Upload/UploadKitchenList'
import { routes } from './Paths'
import { Redirect } from './Redirect'

const DashboardScreen = lazy(() =>
  import('screens/Dashboard/Dashboard').then((module) => ({
    default: module.Dashboard,
  })),
)

export const PrivateRoutes = [
  {
    element: <Redirect path={routes.Home} />,
    path: routes.SignIn,
  },
  {
    children: [
      {
        element: <Upload />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.Upload,
      },
      {
        element: <Upload />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.UploadWithKitchen,
      },
      {
        element: <Queue />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.NextInQueue,
      },
      {
        element: <KitchenInvoiceList />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.Home,
      },
      {
        element: <ViewInvoice />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.View,
      },
      {
        element: <ListInvoice />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.List,
      },
      {
        element: <AnalysisScreen />,
        icon: <SsidChart />,
        name: 'Analysis',
        path: routes.Analysis,
      },
    ],
    element: <DefaultLayout />,
    path: routes.Home,
  },
  {
    children: [
      {
        element: <DashboardScreen />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: `${routes.Process}/:id`,
      },
      {
        element: <EditInvoice />,
        icon: <DashboardIcon />,
        name: 'Invoices',
        path: routes.Edit,
      },
    ],
    element: <WideLayout />,
    path: routes.Process,
  },
]
