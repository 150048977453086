import {
  useMutation,
} from '@apollo/client'
import { Box, useTheme } from '@mui/material'
import type {
  PendingInvoice,
  Kitchen,
  Mutation,
  MutationDeleteManyPendingInvoiceArgs,
  MutationUpdateManyPendingInvoiceArgs,
} from 'api'
import {
  Button,
  KitchenSelect,
  TextField,
  InputAdornment,
  SearchIcon,
  Paper,
  Loader,
  BreadCrumbHeader,
  Modal,
} from 'components'
import dayjs from 'dayjs'
import { useDebounce } from 'hooks'
import { useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from 'routes'
import {
  deleteManyPendingInvoiceMutation,
  updateManyPendingInvoiceMutation,
} from './graphql'
import { useHooks } from './hooks'
import { DataGrid, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import PendingInvoiceActions from './PendingActions'
import PendingInvoiceImages from './PendingImages'
import KitchenInvoiceButtons from './KitchenInvoiceButtons'
import { NoRowsOverlay } from 'components/DataGrid/NoRows'
import DragDropUpload from './DragDropUpload'
import DeleteInvoicesModal from './DeleteInvoicesModal'
import MoveInvoicesModal, { UpdateManyPendingInvoiceFunc } from './MoveInvoicesModal'



export function Upload(): React.ReactElement {
  const theme = useTheme()
  const { id: kitchenId } = useParams()
  const [files, setFiles] = useState<FileList | File[]>()
  const [pendingSearch, setPendingSearch] = useState('')
  const debouncedPendingSearch = useDebounce(pendingSearch, 500)

  const navigate = useNavigate()
  const [selected, setSelected] = useState<number[]>([])
  const [modal, setModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [kitchen, setKitchen] = useState<number | undefined>()
  const [selectedKitchen, setSelectedKitchen] = useState<Kitchen>()

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })

  

  useEffect(() => {
    setSelected([])
  }, [selectedKitchen])
  const [deleteManyPendingInvoice] = useMutation<
    { deleteManyPendingInvoice: Mutation['deleteManyPendingInvoice'] },
    MutationDeleteManyPendingInvoiceArgs
  >(deleteManyPendingInvoiceMutation, {
    refetchQueries: ['pendingInvoices'],
  })
  const [updateManyPendingInvoice] = useMutation<
    { updateManyPendingInvoice: Mutation['updateManyPendingInvoice'] },
    MutationUpdateManyPendingInvoiceArgs
  >(updateManyPendingInvoiceMutation, {
    refetchQueries: ['pendingInvoices'],
  })

  const { pending, pendingCount, merge, uploadFiles, uploading, updateOne } =
    useHooks({
      pendingSearch: debouncedPendingSearch,
      selectedKitchen,
      setToMerge: setSelected,
      pendingPagination: {
        skip: paginationModel.page * paginationModel.pageSize,
        take: paginationModel.pageSize,
      },
    })
  const inputRef = useRef(null)
  const columns = [
    { field: 'ID', headerName: 'ID', width: 75 },
    { field: 'By', headerName: 'By', width: 100 },
    { field: 'Status', headerName: 'Status', width: 150 },
    { field: 'uploaded', headerName: 'Uploaded At', flex: 1 },
    {
      field: 'Images',
      headerName: 'Images',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent row selection
        }}
      >
        <PendingInvoiceImages
          pi={params.row.pi}
        />
      </div>
        )
      },
    },
    {
      field: 'extraction',
      headerName: 'Extraction',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const number = params.row.pi.suggestions.length
        return number > 1
          ? `${params.row.pi.suggestions.length} rows extracted`
          : `Nothing extracted`
      },
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <PendingInvoiceActions
            pendingInvoice={params.row.pi}
            updateInvoice={updateOne}
          />
        )
      },
    },
  ]

  const toRows = (pis?: PendingInvoice[]) => {
    if (!pis) {
      return []
    }
    return pis.map((pi: PendingInvoice) => ({
      id: pi.id,
      By: `${String(pi.createdBy.firstName)} ${String(
        pi.createdBy.lastName === 'USER' ? '' : pi.createdBy.lastName,
      )}`,
      createdAt: pi.invoice?.createdAt
        ? dayjs(pi.invoice.createdAt).format('DD/MM/YYYY')
        : '-',
      ID: pi.id,
      Images: pi.signedImages,
      Status: `${pi.status} | ${String(pi.updatedBy.firstName)}`,
      Supplier: pi.invoice?.supplier.name ?? '-',
      Total: pi.invoice?.invoiceTotal ?? '-',
      uploaded: dayjs(pi.createdAt).format('DD/MM/YYYY HH:mm'),
      pi,
      extraction: `${pi.suggestions.length}`,
    }))
  }

  const handleSelectionModelChange = (newSelection: unknown) => {
    setSelected(newSelection as number[])
  }

  return (
    <Box>
      <BreadCrumbHeader
        crumbs={[{ label: 'Invoices', route: '/' }, { label: 'Add Invoices' }]}>
        <div className="flex justify-between w-full">
          <div>
            <KitchenSelect
              kitchenId={Number.parseInt(kitchenId)}
              onChange={(kitchen) => {
                setSelectedKitchen(kitchen)
                navigate(
                  routes.UploadWithKitchen.replace(
                    ':id',
                    kitchen?.id.toString() ?? '',
                  ),
                )
              }}
            />
          </div>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setPendingSearch(e.target.value)
            }}
            placeholder="Search for users"
            required={false}
            size="small"
            sx={{ width: '300px' }}
            variant="outlined"
          />
          <div>
            <Box
              onClick={() => {
                // @ts-expect-error Ref exists
                inputRef.current.click()
              }}
              onDragOver={(e) => {
                e.preventDefault()
              }}
              onDrop={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (uploading) {
                  return
                }
                const { files } = e.dataTransfer
                for (let i = 0; i < files.length; i++) {
                  const file = files.item(i)
                  if (!file) {
                    continue
                  }
                  if (
                    file.type !== 'application/pdf' &&
                    !file.type.includes('image/')
                  ) {
                    // eslint-disable-next-line no-alert
                    window.alert(`invalid file type "${file.type}"`)
                    return
                  }
                }
                setFiles(files)
              }}
              onKeyDown={() => ''}
              role="button"
              sx={{
                cursor: 'pointer',
                justifyContent: 'center',
                marginBottom: '20px',
                display: 'flex',
              }}
              tabIndex={0}>
              {uploading ? (
                <Loader />
              ) : (
                <DragDropUpload
                  inputRef={inputRef}
                  setFiles={setFiles}
                  selectedKitchen={selectedKitchen ?? null}
                />
              )}
            </Box>
          </div>
        </div>
      </BreadCrumbHeader>

      {selectedKitchen && (
        <>
          <Box sx={{ paddingBottom: '20px' }}>
            <DataGrid
              slots={{
                toolbar: () => (
                  <div className="w-full flex justify-between pt-2 mx-2">
                    <KitchenInvoiceButtons
                      selected={selected}
                      merge={merge}
                      setModal={setModal}
                      setDeleteModal={setDeleteModal}
                      theme={theme}
                    />

                    <GridToolbar />
                  </div>
                ),
                noRowsOverlay: () => <NoRowsOverlay loading />,
              }}
              rowHeight={75}
              checkboxSelection
              rows={toRows(pending)}
              columns={columns}
              rowCount={pendingCount ?? -1}
              onRowSelectionModelChange={handleSelectionModelChange}
              pageSizeOptions={[5, 10, 25, 100]}
              paginationModel={paginationModel}
              paginationMode="server"
              onPaginationModelChange={setPaginationModel}
            />
          </Box>
        </>
      )}

      <Modal
        onClick={() => {
          // @ts-expect-error No need to pass undefined
          setFiles()
        }}
        open={!!files}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <Paper
          onClick={(e) => {
            e.stopPropagation()
          }}
          sx={{
            padding: '40px',
          }}>
          <Box>
            Do you want to upload <b>{files?.length}</b> files to{' '}
            <b>{selectedKitchen?.name}</b>
          </Box>
          <Button
            onClick={() => {
              // @ts-expect-error No need to pass undefined
              setFiles()
            }}>
            CLOSE
          </Button>
          <Button
            onClick={() => {
              if (!files) {
                return
              }

              uploadFiles(files)
              // @ts-expect-error No need to pass undefined
              setFiles()
            }}>
            CONFIRM
          </Button>
        </Paper>
      </Modal>
      <MoveInvoicesModal
        selected={selected}
        setSelected={setSelected}
        selectedKitchen={selectedKitchen}
        setModal={setModal}
        modal={modal}
        kitchen={kitchen}
        setKitchen={setKitchen}
        updateManyPendingInvoice={
          updateManyPendingInvoice as UpdateManyPendingInvoiceFunc
        }
      />
      <DeleteInvoicesModal
        deleteModal={deleteModal}
        setDeleteModal={setDeleteModal}
        selected={selected}
        setSelected={setSelected}
        selectedKitchen={selectedKitchen}
        deleteManyPendingInvoice={deleteManyPendingInvoice}
      />
    </Box>
  )
}
