import { Button } from "components";
import dayjs from "dayjs";
import { routes } from "routes";
import RevertButton from "../RevertButton";
import { GridRenderCellParams } from "@mui/x-data-grid";
import PendingInvoiceImages from "screens/Upload/PendingImages";

export const completeColumns = [
    {
      field: 'createdAt',
      headerName: 'Entry Date',
      width: 180,
      valueFormatter: (params: any) => dayjs(params).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'kitchen',
      headerName: 'Kitchen',
      width: 150,
      valueGetter: (params: any) => params.name,
    },
    {
      field: 'supplier',
      headerName: 'Supplier',
      width: 150,
      valueGetter: (params: any) => params.name,
    },
    { field: 'invoiceId', headerName: 'Inv ID', width: 150 },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      width: 150,
      valueFormatter: (params: any) => (params ? dayjs(params).format('DD/MM/YYYY') : ''),
    },
    { field: 'invoiceTotal', headerName: 'Invoice Total', width: 150 },
    {
      field: 'entries',
      headerName: 'Items',
      width: 150,
      valueGetter: (params: any) => params.length,
    },
    {
      field: 'createdBy',
      headerName: 'By',
      width: 180,
      valueGetter: (params: any) => `${params.firstName} ${params.lastName}`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => (
        <>
          <Button
            onClick={() =>
              window.open(routes.View.replace(':id', params.row.id.toString()), '_blank')
            }
          >
            View
          </Button>
          <Button
            onClick={() =>
              window.open(routes.Edit.replace(':id', params.row.id.toString()), '_blank')
            }
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  export const incompleteColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Entry Date',
      width: 180,
      valueFormatter: (params: any) => dayjs(params).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'kitchen',
      headerName: 'Kitchen',
      width: 150,
      valueGetter: (params: any) => params.name,
    },
    {
      field: 'rejectionReason',
      headerName: 'Rejection Reason',
      flex: 1,
    },
    {
      field: 'rejectionReasonNotes',
      headerName: 'Notes',
      flex: 1,
    },
    {
      field: 'images',
      headerName: 'Images',
      flex: 1,
            renderCell: (params: GridRenderCellParams) => {
        return (
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent row selection
        }}
      >
        <PendingInvoiceImages
          pi={params.row.pi}
        />
      </div>
        )
      },
    },
    {
      field: 'createdBy',
      headerName: 'By',
      width: 180,
      valueGetter: (params: any) => `${params.firstName} ${params.lastName}`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params: any) => (
        <RevertButton pendingInvoiceId={params.row.id} />
      ),
    },
  ];